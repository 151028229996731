import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/ui/Button";
import GTM from "../../GTM";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import useOutsideClick from "../../hooks/useOutsideClick";
import { CUSTOM_DATE } from "../../utils/helpers";
import PoliciesAndDivisions from "./PoliciesAndDivisions";
import {
    absenceType,
    disabilityType,
    leaveType,
    paymentHistoryType,
    changesReportType,
} from "../../constants";
import MultiSelectComponent from "../../components/MultiSelect";
import Calendar from "../../components/Calendar";
import moment from "moment";
import { format } from 'date-fns';

const ReportFilters = ({
    allowedPoliciesAndDivisions,
    handleApplyFilter,
    selectedValues,
    coverageTypeOptions,
    setSelectedCoverageType,
    reportingPeriod,
    status,
    reportingGroup,
    reportViewType,
    defaultReportingPeriod,
    organizationName,
    userSelectedOrganizationReports,
    reportId,
    relatedOrganizationNames,
    selectedCoverageType,
    selectedStatus,
    setSelectedStatus,
    selectedPolicyDivision,
    selectedReportingGroup,
    handlePolicyDivision,
    includeHistoricalLeave,
    periodScheduleType,
    periodStatus,
    protectionStatus,
    defaultIncludeHistoricalLeaveValue,
    defaultPeriodStatusValue,
    defaultPeriodScheduleTypeValue,
    defaultProtectionStatusValue,
    onIncludeHistoricalValueChange,
    onReportingPeriodChange,
    onHandleReset,
    enableReset,
    enableApply,
    setEnableReset,
    setEnableApplyButton,
    setPeriodScheduleTypeValue,
    setPeriodStatusValue,
    setProtectionStatusValue,
    setSelectedReportingGroup,
}) => {
    const gtm = new GTM(null);
    const historicalLeaveToggleRef = useRef(null);
    const historicalLeaveDropdownRef = useRef(null);
    const reportingPeriodRef = useRef(null);
    const reportingPeriodDropdownRef = useRef(null);
    const daterangeref = useRef(null);
    const popupRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const { displayReportingGroupFilter } = useFlags();
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [isHistoricalLeaveDropdowOpen, setIsHistoricalLeaveDropdowOpen] =
        useState(false);
    const [isOpenReportingPeriod, setOpenReportingPeriod] = useState(false);
    const [selectedReportingPeriod, setSelectedReportingPeriod] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [enableApplyButton, setEnableApply] = useState(enableApply);
    const [showCalendar, setShowCalendar] = useState(false);
    const [reportingPeriodFocusedIndex, setReportingPeriodFocusedIndex] = useState(0);


    const ReportingPeriodOutSideClick = useOutsideClick(reportingPeriodDropdownRef, reportingPeriodRef);
    const isClickedOutside = useOutsideClick(popupRef, daterangeref);
    const isHistoricalLeaveOutSideClick = useOutsideClick(historicalLeaveToggleRef, historicalLeaveDropdownRef);

    useEffect(() => {
        if (isHistoricalLeaveOutSideClick) setIsHistoricalLeaveDropdowOpen(false);
        if (ReportingPeriodOutSideClick) setOpenReportingPeriod(false);
        if (isClickedOutside) setShowCalendar(false);
    }, [isHistoricalLeaveOutSideClick, ReportingPeriodOutSideClick, isClickedOutside]);
    useEffect(() => {
        const focusedElement = document.getElementById(`option-${reportingPeriodFocusedIndex}`);
        if (focusedElement) focusedElement.focus();
    }, [isOpenReportingPeriod, reportingPeriodFocusedIndex]);



    const optionRefs = useRef([]);
    const handleGtmTags = (label) => {
        gtm.Event(
            "custom_event",
            "filter",
            "select",
            label,
            "report detail",
            reportViewType.toLowerCase(),
            "field_interaction",
            ""
        );
    };

    const handleToggleHistoricalLeaveDropdown = () => {
        setIsHistoricalLeaveDropdowOpen(!isHistoricalLeaveDropdowOpen);
        const defaultIndex = includeHistoricalLeave.findIndex(
            (option) =>
                option.value === defaultIncludeHistoricalLeaveValue.value
        );
        setFocusedIndex(defaultIndex >= 0 ? defaultIndex : null);
    };


    const handleReportingPeriodChange = (item) => {
        if (item.value !== CUSTOM_DATE) {
            onReportingPeriodChange(item);
        }
        handleGtmTags("reporting period");

        if (item.value === CUSTOM_DATE) {
            setShowCalendar(true); // Show the date range picker
        } else {
            setShowCalendar(false);
        }
    };

    useEffect(() => {
        if (!isHistoricalLeaveDropdowOpen) return;

        const options = Array.from(
            historicalLeaveDropdownRef.current.querySelectorAll(
                ".select-option"
            )
        );

        const focusOption = (index) => {
            if (options[index]) {
                options[index].focus();
            }
        };

        const handleKeyDown = (e) => {
            const focusedElement = document.activeElement;
            const currentIndex = options.findIndex(
                (option) => option === focusedElement
            );

            switch (e.key) {
                case "Tab":
                    e.preventDefault();
                    const newIndexTab = e.shiftKey
                        ? (currentIndex - 1 + options.length) % options.length
                        : (currentIndex + 1) % options.length;
                    focusOption(newIndexTab);
                    break;

                case "Escape":
                    e.preventDefault();
                    setIsHistoricalLeaveDropdowOpen(false);
                    break;

                case "ArrowDown":
                    e.preventDefault();
                    focusOption((currentIndex + 1) % options.length);
                    break;

                case "ArrowUp":
                    e.preventDefault();
                    focusOption(
                        (currentIndex - 1 + options.length) % options.length
                    );
                    break;

                case "Enter":
                    if (focusedElement.classList.contains("select-option")) {
                        const selectedOption = includeHistoricalLeave.find(
                            (option) =>
                                option.label ===
                                focusedElement.textContent.trim()
                        );
                        if (selectedOption) {
                            handleIncludeHistoricalValueChange(selectedOption);
                            setIsHistoricalLeaveDropdowOpen(false);
                        }
                    }
                    break;

                default:
                    break;
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isHistoricalLeaveDropdowOpen]);

    const handleHistoricalLeaveKeyDown = (e) => {
        switch (e.key) {
            case "Enter":
            case " ":
                e.preventDefault();
                handleToggleHistoricalLeaveDropdown();
                break;

            case "ArrowDown":
                e.preventDefault();
                setIsHistoricalLeaveDropdowOpen(true);
                const defaultIndex = includeHistoricalLeave.findIndex(
                    (option) =>
                        option.value ===
                        defaultIncludeHistoricalLeaveValue.value
                );
                setFocusedIndex(defaultIndex >= 0 ? defaultIndex : null);
                break;

            case "Escape":
                e.preventDefault();
                setIsHistoricalLeaveDropdowOpen(false);
                setFocusedIndex(null);
                break;

            default:
                break;
        }
    };

    const handlePolicyCheckboxChange = (policyId) => {
        const policyDivisions =
            filteredPoliciesAndDivisions.find(
                (item) => item.policyId === policyId
            )?.divisions || [];
        const divisionIds = policyDivisions.map(
            (division) => `${policyId}-${division.divisionId}`
        );

        handlePolicyDivision((prevSelected) => {
            if (prevSelected.includes(policyId)) {
                return prevSelected.filter(
                    (item) => item !== policyId && !divisionIds.includes(item)
                );
            } else {
                return [...prevSelected, policyId, ...divisionIds];
            }
        });
    };

    const filteredPoliciesAndDivisions =
        searchTerm.length < 3
            ? allowedPoliciesAndDivisions
            : allowedPoliciesAndDivisions.filter((item) => {
                return (
                    item.policyId
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    item.divisions.some(
                        (division) =>
                            division.divisionName
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                            division.divisionId
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase())
                    )
                );
            });

    const handleDivisionCheckboxChange = (divisionId, policyId) => {
        handlePolicyDivision((prevSelected) => {
            const policyDivisions =
                filteredPoliciesAndDivisions
                    .find((item) => item.policyId === policyId)
                    ?.divisions.map(
                        (division) => `${policyId}-${division.divisionId}`
                    ) || [];

            const newSelected = prevSelected.includes(divisionId)
                ? prevSelected.filter((item) => item !== divisionId)
                : [...prevSelected, divisionId];

            const allPolicyDivisionsSelected = policyDivisions.every((id) =>
                newSelected.includes(id)
            );

            if (allPolicyDivisionsSelected) {
                return [...newSelected, policyId];
            } else {
                return newSelected.filter((item) => item !== policyId);
            }
        });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectAll = () => {
        const allItems = filteredPoliciesAndDivisions.flatMap((item) => [
            item.policyId,
            ...item.divisions.map(
                (division) => `${item.policyId}-${division.divisionId}`
            ),
        ]);
        handlePolicyDivision(allItems);
    };

    const handleUnselectAll = () => {
        handlePolicyDivision([]);
    };

    const clearSearch = () => {
        setSearchTerm("");
    };

    const handleReset = () => {
        onHandleReset();
        handleGtmTags("reset filters");
    };

    useEffect(() => {
        setEnableApply(false);
    }, [enableApply]);

    //

    const onApplyFilterClick = (item) => {
        handleApplyFilter({
            ...item,
        });
        handleGtmTags("apply filters");
    };

    const handleIncludeHistoricalValueChange = (selectedOptions) => {
        setIsHistoricalLeaveDropdowOpen(!isHistoricalLeaveDropdowOpen);
        onIncludeHistoricalValueChange(selectedOptions);
    };
    useEffect(() => {
        if (enableApply) {
            let isEnabled = false;

            if (reportViewType === paymentHistoryType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    selectedPolicyDivision.length > 0;
            } else if (reportViewType === leaveType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    defaultPeriodScheduleTypeValue.length > 0 &&
                    defaultPeriodStatusValue.length > 0 &&
                    defaultProtectionStatusValue.length > 0 &&
                    (displayReportingGroupFilter
                        ? selectedReportingGroup.length > 0
                        : true);
            } else if (reportViewType === disabilityType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    selectedPolicyDivision.length > 0;
            } else if (reportViewType === absenceType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    selectedPolicyDivision.length > 0 &&
                    defaultPeriodScheduleTypeValue.length > 0 &&
                    defaultPeriodStatusValue.length > 0 &&
                    defaultProtectionStatusValue.length > 0 &&
                    (displayReportingGroupFilter
                        ? selectedReportingGroup.length > 0
                        : true);
            } else if (reportViewType === changesReportType) {
                isEnabled =
                    selectedStatus.length > 0 &&
                    (displayReportingGroupFilter
                        ? selectedReportingGroup.length > 0
                        : true);
            }
            setEnableApply(isEnabled);
        }
    }, [
        selectedCoverageType,
        selectedStatus,
        selectedReportingGroup,
        defaultPeriodScheduleTypeValue,
        defaultPeriodStatusValue,
        defaultProtectionStatusValue,
        selectedPolicyDivision,
        enableApply,
        reportViewType,
    ]);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const getReportingPeriondMessage = () => {
        if (reportViewType === disabilityType) {
            return "Reporting period includes any claims that had a status update within the selected date range.";
        } else if (reportViewType === leaveType) {
            return "Reporting period includes any leaves that had a status update within the selected date range.";
        } else if (reportViewType === paymentHistoryType) {
            return "Reporting period includes any claims that had a payment status date within the selected date range.";
        } else if (reportViewType === changesReportType) {
            return "This report includes data between the period selected and the current date.";
        } else {
            return "Reporting period includes any claims or leaves that had a status update within the selected date range.";
        }
    };

    const formatedCoverageType = selectedCoverageType
        ?.map((item) => item.value)
        .join(", ");

    useEffect(() => {
        if (defaultReportingPeriod) {
            setSelectedReportingPeriod(defaultReportingPeriod.label);
        }
    }, [defaultReportingPeriod]);

    const toggleDropdown = () => setOpenReportingPeriod(!isOpenReportingPeriod);
    const closeDropdown = () => setOpenReportingPeriod(false);

    const handleOptionClick = (option) => {
        setSelectedReportingPeriod(option.label);
        handleReportingPeriodChange(option);
        closeDropdown();
        if (option.label === CUSTOM_DATE) {
            setShowCalendar(true);
        }
    };

    const handleConfirmButton = (item) => {
        setShowCalendar(false);
        let customDate = {
            value: CUSTOM_DATE,
            label: CUSTOM_DATE + moment(item.startDate).format("MM/DD/YYYY") + " - " + moment(item.endDate).format("MM/DD/YYYY"),
            startDate: item.startDate,
            endDate: item.endDate,
        };
        onReportingPeriodChange(customDate);
    };

    const handleDropdownToggleKeyboardNavigation = (e) => {
        switch (e.key) {
            case "Enter":
            case " ":
                e.preventDefault();
                toggleDropdown();
                break;
            case "ArrowDown":
                if (!isOpenReportingPeriod) {
                    setOpenReportingPeriod(true);
                } else {
                    e.preventDefault();
                    setReportingPeriodFocusedIndex((prev) =>
                        prev === reportingPeriod.length - 1 ? 0 : Math.min(reportingPeriod.length - 1, prev + 1)
                    );
                }
                break;
            case "ArrowUp":
                setReportingPeriodFocusedIndex((prev) => Math.max(0, prev - 1));
                break;
            case "Escape":
                e.preventDefault();
                setOpenReportingPeriod(false);
                setReportingPeriodFocusedIndex(0);
                break;
            default:
                break;
        }
    };


    useEffect(() => {
        if (showCalendar && daterangeref.current) {
            daterangeref.current.focus();
        }
    }, [showCalendar]);

    // Handles navigation through the options in the dropdown using Arrow keys
    function handleDropdownOptionKeyboardNavigation(e) {
        const options = Array.from(document.querySelectorAll('.reportingPeriod-dropdown li'));
        let currentIndex = options.findIndex(option => option.classList.contains('option-selected'));

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            currentIndex = (currentIndex + 1) % options.length;
            options[currentIndex].focus();
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            currentIndex = (currentIndex - 1 + options.length) % options.length;
            options[currentIndex].focus();
        } else if (e.key === 'Escape') {
            e.preventDefault();
            closeDropdown();
        }
    }

    // Select an option when Enter is pressed
    const handleOptionKeyboardAction = (e, option) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleOptionClick(option);
        }
    }
    const get30DaysAgo = () => {
        const thirtyDaysAgo = new Date(); thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        return format(thirtyDaysAgo, "yyyy-MM-dd");
    };

    return (
        <div className="d-flex flex-direction">
            <div className="filter-header">
                <div className="organization-info">
                    <label className="pb-0 header" htmlFor="organization-name">
                        Organization
                    </label>
                    <p className="sub-text" id="organization-name">
                        {organizationName.length < 30 ? (
                            organizationName
                        ) : (
                            <div className="parameter-text d-block">
                                {organizationName}
                            </div>
                        )}
                    </p>
                </div>

                <div className="related-orgs-info">
                    <label className="pb-0 header" htmlFor="related-orgs">
                        Related orgs
                    </label>
                    <p className="sub-text" id="related-orgs">
                        {userSelectedOrganizationReports[reportId]?.related
                            .length > 0 ? (
                            <div className="parameter-text d-block">
                                {relatedOrganizationNames}
                            </div>
                        ) : (
                            <span className="d-block">None</span>
                        )}
                    </p>
                </div>

                <div className="filter-actions">
                    <>
                        <div
                            className={`fs-16 text-semibold align-content-center ${!enableReset ? "disabled-text" : "hyper-links"
                                }`}
                            role="button"
                            aria-disabled={!enableReset}
                            tabIndex={enableReset ? 0 : -1}
                            onClick={enableReset ? handleReset : undefined}
                            aria-live="polite"
                            onKeyDown={(e) => {
                                if (
                                    enableReset &&
                                    (e.key === "Enter" || e.key === " ")
                                ) {
                                    handleReset();
                                    e.preventDefault();
                                }
                            }}
                        >
                            Reset filters
                            <i
                                className="fa-solid fa-arrow-rotate-left pl-8p"
                                aria-hidden="true"
                            ></i>
                        </div>
                        <div
                            aria-live="polite"
                            style={{
                                position: "absolute",
                                height: 0,
                                width: 0,
                                overflow: "hidden",
                            }}
                        >
                            {enableReset && "Reset filters button is active"}
                        </div>
                    </>
                    <div className="fs-16">
                        <Button
                            text="Apply filters"
                            onClick={() => onApplyFilterClick(selectedValues)}
                            disabled={!enableApplyButton}
                            outline={true}
                            aria-label="Apply filters"
                        />
                    </div>
                </div>
            </div>

            <div className="row" style={{ rowGap: 16, marginTop: "10px" }}>
                <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                    <label className="header pb-0" htmlFor="reporting-period">
                        Reporting period
                    </label>
                    <div
                        ref={reportingPeriodRef}
                        style={{ position: "relative", display: "inline-block" }}
                    >
                        <i
                            className="fa-solid fa-circle-info"
                            style={{ color: "#FFFFFF", paddingLeft: 4, cursor: "pointer" }}
                            tabIndex={0}
                            aria-label="More information about reporting period"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onFocus={handleMouseEnter}
                            onBlur={handleMouseLeave}
                        ></i>
                        {showTooltip && (
                            <div
                                className="reporting-period-tooltip"
                                role="tooltip"
                                id="reporting-period-tooltip"
                            >
                                {getReportingPeriondMessage()}
                                <div className="tooltipArrow"></div>
                            </div>
                        )}
                    </div>
                    <div className="reporting-period" >
                        <div onClick={toggleDropdown} aria-expanded={isOpenReportingPeriod}
                            aria-controls="dropdown-options"
                            role="combobox"
                            tabIndex={0}
                            aria-label="reporting period"
                            onKeyDown={(e) => handleDropdownToggleKeyboardNavigation(e)}
                        >
                            <div
                                className="reportTableFilter"
                            // Update here
                            >
                                <span>{selectedReportingPeriod}</span>
                                <i
                                    className={`fa-solid fa-angle-${isOpenReportingPeriod ? "up" : "down"}`}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            {isOpenReportingPeriod && (
                                <ul
                                    className="reportingPeriod-dropdown"
                                    id="dropdown-options"
                                    ref={reportingPeriodDropdownRef}
                                    aria-expanded={isOpenReportingPeriod}
                                    tabIndex={-1}
                                    role="listbox"
                                    onKeyDown={(e) => handleDropdownOptionKeyboardNavigation(e)} // Update here
                                >
                                    {reportingPeriod.map((option, index) => (
                                        <li
                                            key={option.value}
                                            className={`dropdown-option ${option.label === selectedReportingPeriod ? "option-selected" : ""}`}
                                            role="option"
                                            aria-selected={option.label === selectedReportingPeriod}
                                            onClick={() => handleOptionClick(option)}
                                            tabIndex={0}
                                            onKeyDown={(e) => handleOptionKeyboardAction(e, option)} // Update here
                                            id={`option-${index}`}
                                        >
                                            {option.label}
                                            {option.label.includes(CUSTOM_DATE) && <i className="fa-solid fa-angle-right" aria-hidden="true"></i>}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {showCalendar && (
                            <div ref={daterangeref}
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    if (e.key === "Escape") {
                                        setShowCalendar(false);  // Close the calendar on Escape
                                        e.stopPropagation();  // Stop propagation to prevent other handlers
                                        e.preventDefault();  // Prevent default behavior
                                    }
                                }}
                            >
                                <div ref={popupRef}>
                                    <Calendar
                                        reportViewType={reportViewType}
                                        setShowReportingCalendar={setShowCalendar}
                                        handleConfirmButton={handleConfirmButton}
                                        startDate={defaultReportingPeriod.startDate}
                                        endDate={defaultReportingPeriod.endDate}
                                        minimumStartDate={reportViewType === changesReportType ? get30DaysAgo() : new Date().setFullYear(new Date().getFullYear() - 5)}
                                        maximumEndDate={new Date()}
                                        setOpenReportingPeriod={setOpenReportingPeriod}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {reportViewType !== "Absence" && reportViewType !== changesReportType && (
                    <MultiSelectComponent
                        headerLabel="Coverage type" options={coverageTypeOptions} selectedOptions={selectedCoverageType} setSelectedOptions={setSelectedCoverageType} setEnableReset={setEnableReset}
                        setEnableApplyButton={setEnableApplyButton}
                        handleGtmTags={handleGtmTags}
                        gtmTagLabel="coverage type"
                    />
                )}
                {reportViewType !== changesReportType && (
                    <MultiSelectComponent headerLabel={reportViewType === paymentHistoryType ? "Payment status" : "Status"} options={status} selectedOptions={selectedStatus} setSelectedOptions={setSelectedStatus}
                        setEnableReset={setEnableReset}
                        setEnableApplyButton={setEnableApplyButton}
                        handleGtmTags={handleGtmTags}
                        gtmTagLabel="status"
                    />
                )}

                {(reportViewType === disabilityType || reportViewType === absenceType || reportViewType === paymentHistoryType) && (
                    <PoliciesAndDivisions
                        filteredPoliciesAndDivisions={filteredPoliciesAndDivisions} selectedPolicyDivision={selectedPolicyDivision} handleSelectAll={handleSelectAll} handleUnselectAll={handleUnselectAll}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange} clearSearch={clearSearch} handlePolicyCheckboxChange={handlePolicyCheckboxChange}
                        handleDivisionCheckboxChange={handleDivisionCheckboxChange}
                    />
                )}

                {(reportViewType === leaveType || reportViewType === absenceType) && (
                    <div
                        className="col-lg-3 col-md-6 col-sm-4 col-6 col-print"
                        style={{ position: "relative" }}
                    >
                        <label
                            className="header pb-0"
                            htmlFor="include-historical-leave"
                        >
                            Include historical leave
                        </label>
                        <div
                            ref={historicalLeaveToggleRef}
                            className="d-flex justify-content-between cursor-pointer customFilterSection"
                            onClick={handleToggleHistoricalLeaveDropdown}
                            onKeyDown={handleHistoricalLeaveKeyDown}
                            role="combobox"
                            aria-expanded={isHistoricalLeaveDropdowOpen}
                            aria-controls="multi-select-dropdown"
                            aria-label="Include historical leave"
                            aria-describedby="include-historical-leave-description"
                            id="include-historical-leave"
                            tabIndex={0}
                            aria-activedescendant={`option-${focusedIndex}`}
                        >
                            <div className="customFilterDropDown">
                                {defaultIncludeHistoricalLeaveValue.label}
                            </div>
                            <div
                                className="d-flex"
                                style={{
                                    fontSize: 17,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    gap: 8,
                                }}
                            >
                                <i
                                    className={`fa-solid fa-angle-${isHistoricalLeaveDropdowOpen
                                        ? "up"
                                        : "down"
                                        }`}
                                ></i>
                            </div>
                        </div>
                        {isHistoricalLeaveDropdowOpen && (
                            <div className="custom-dropdown-container">
                                <div
                                    ref={historicalLeaveDropdownRef}
                                    id="single-select-dropdown"
                                    className="section-card custom-section-dropdown"
                                    role="listbox"
                                    aria-labelledby="multi-select-label"
                                    aria-live="assertive"
                                    style={{ paddingTop: 0 }}
                                >
                                    <div
                                        className="custom-scrollbar"
                                        style={{ maxHeight: 250, borderTop: 0 }}
                                    >
                                        {includeHistoricalLeave.length === 0 ? (
                                            <div className="d-flex justify-content-center my-8p text-center">
                                                No options
                                            </div>
                                        ) : (
                                            includeHistoricalLeave.map(
                                                (option, index) => (
                                                    <div
                                                        key={option.value}
                                                        className={`select-option ${focusedIndex === index ? "focused" : ""}`}
                                                        role="option"
                                                        onClick={() => handleIncludeHistoricalValueChange(option)}
                                                        ref={(el) => (optionRefs.current[index] = el)}
                                                        tabIndex={-1}
                                                        aria-selected={focusedIndex === index ? "true" : "false"}
                                                    >
                                                        <label className="optionLabel" style={{ marginLeft: 8 }}>{option.label}</label>
                                                    </div>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {(reportViewType === leaveType || reportViewType === changesReportType || reportViewType === absenceType) && displayReportingGroupFilter && (
                    <MultiSelectComponent
                        headerLabel="Reporting group"
                        options={reportingGroup}
                        selectedOptions={selectedReportingGroup}
                        setSelectedOptions={setSelectedReportingGroup}
                        setEnableReset={setEnableReset}
                        setEnableApplyButton={setEnableApplyButton}
                        anableSearch={true}
                    />
                )}
                {(reportViewType === leaveType || reportViewType === absenceType) && (
                    <>
                        <MultiSelectComponent
                            headerLabel="Period schedule type"
                            options={periodScheduleType}
                            selectedOptions={defaultPeriodScheduleTypeValue}
                            setSelectedOptions={setPeriodScheduleTypeValue}
                            setEnableReset={setEnableReset}
                            setEnableApplyButton={setEnableApplyButton}
                        />
                        <MultiSelectComponent
                            headerLabel="Period status"
                            options={periodStatus}
                            selectedOptions={defaultPeriodStatusValue}
                            setSelectedOptions={setPeriodStatusValue}
                            setEnableReset={setEnableReset}
                            setEnableApplyButton={setEnableApplyButton}
                        />
                        <MultiSelectComponent
                            headerLabel="Protection status"
                            options={protectionStatus}
                            selectedOptions={defaultProtectionStatusValue}
                            setSelectedOptions={setProtectionStatusValue}
                            setEnableReset={setEnableReset}
                            setEnableApplyButton={setEnableApplyButton}
                        />
                    </>
                )}

                {reportViewType === "Absence" && (
                    <div className="col-lg-4 col-md-6 col-sm-4 col-6 col-print d-flex align-items-end coverageType-section">
                        <label className="header pb-0">Coverage types:</label>
                        &nbsp;
                        <span className="coverageType-text d-block fs-sm">
                            {selectedCoverageType?.length > 0
                                ? formatedCoverageType
                                : "None"}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportFilters;
