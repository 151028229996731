import React, { useState, useEffect, useCallback } from "react";
import EmployeeRecord from "./EmployeeRecord.js";
import ClaimLeaveHistoryTable from "./ClaimLeaveHistoryTable";
import { useGetEmployeeDetailsQuery } from "./../../slices/employeesApiSlice";
import {
    useLocation,
    useParams,
    useSearchParams,
    useNavigate,
} from "react-router-dom";
import moment from "moment";
import LeaveRecord from "./LeaveRecord";
import FooterNote from "./FooterNote";
import ClaimRecord from "./ClaimRecord";
import { recordsType } from "../../constants/index";
import { useSelector, useDispatch } from "react-redux";
import {
    addLeaveNo,
    addClaimNo,
    resetRelatedNos,
} from "./../../slices/leaveClaimRecordsSlice";
import { Breadcrumb } from "../../components/ui/Breadcrumb.js";
import Loader from "../../components/ui/Loader.js";
import ErrorBoundary from "../../components/ErrorBoundary.js";
import NoRecords from "../../components/alerts/NoRecords.js";
import { DataToPrint } from "../../components/print/DataToPrint.js";
import { useReactToPrint } from "react-to-print";
import { logout } from "./../../slices/authSlice";
import { ssnAccess } from "../../constants";
import RequestFailed from "../../components/alerts/RequestFailed";

import {
    addLastSelectedEmployee,
    addSelectedOrganization,
    setCurrentSelectedOrganization,
} from "../../slices/organizationSlice.js";
import GTM from "../../GTM.js";

function EmployeeClaimLeave(props) {
    const { orgId, id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);

    const {
        selectedOrganizations: userSelectedOrganizations,
        organizationsAccess,
    } = useSelector((state) => state.organizations);

    const url = window.location.pathname + window.location.search;

    useEffect(() => {
        if (isNaN(orgId)) {
            navigate(`/`, { replace: true });
            return;
        }

        if (!(orgId in userSelectedOrganizations)) {
            dispatch(
                addSelectedOrganization({
                    id: orgId,
                    organization: null,
                    related: [],
                })
            );
        }

        dispatch(
            addLastSelectedEmployee({
                organizationId: orgId,
                path: url,
            })
        );
    }, []);

    const gtm = new GTM(null);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getOrganizationIds = () => {
        const ids = searchParams.get("ids");

        if (ids && typeof ids === "string" && ids.length > 0) {
            return ids;
        }
        return orgId;
    };

    const {
        data: employee = null,
        isLoading,
        isFetching,
        isUninitialized,
        isError,
        error,
    } = useGetEmployeeDetailsQuery({
        employeeId: id,
        organizationId: getOrganizationIds(),
    });
    const { lastSelectedOrganization } = useSelector(
        (state) => state.organizations
    );

    let previousUrls = props.previousUrl;
    let page_referrer = previousUrls[previousUrls.length - 1];
    let page_referrer_title = "";

    if (page_referrer === "/search") {
        page_referrer_title = "search";
    } else {
        page_referrer_title = "employee search";
    }

    useEffect(() => {
        const userName =
            lastSelectedOrganization?.ORGNIZATIONNAME.toLowerCase();
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal === true ? "internal" : "external";

        gtm.PageView(
            "claim & leave",
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL + page_referrer}`,
            page_referrer_title,
            orgId
        );
    }, []);

    useEffect(() => {
        dispatch(setCurrentSelectedOrganization(orgId));
    }, []);

    const dispatch = useDispatch();

    const location = useLocation();

    let ssnDisplay = "";

    if (orgId in organizationsAccess) {
        ssnDisplay = organizationsAccess[orgId].SSNACCESS;
    }

    const [claimHistoryData, setClaimHistoryData] = useState([]);
    const [currentSSNState, setCurrentSSNState] = useState(
        location.state?.ssnState
    );

    useEffect(() => {
        if (error && error.status === 401) {
            dispatch(logout());
        }
    }, [error]);

    const [employeesLastFetched, setEmployeesLastFetched] = useState("-");

    const [orgIdNo, setOrgIdNo] = useState(null);

    const handleSelectedOrgId = useCallback((orgId) => {
        setOrgIdNo(orgId);
    });

    const handleLeaveNoSelection = useCallback((leaveNo) => {
        dispatch(addLeaveNo(leaveNo));
    }, []);

    const handleClaimNoSelection = useCallback((claimNo) => {
        dispatch(addClaimNo(claimNo));
    }, []);

    useEffect(() => {
        if (!isLoading) {
            const claim = searchParams.get("claim");
            const leave = searchParams.get("leave");

            if (claim) {
                dispatch(addClaimNo(claim));
            }

            if (leave) {
                dispatch(addLeaveNo(leave));
            }
        }
    }, [isLoading]);

    const [printing, setPrinting] = React.useState(false);
    const componentRef = React.useRef();

    const print = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setPrinting(false);
        },
    });

    useEffect(() => {
        if (printing === true) {
            setTimeout(() => {
                print();
            }, 200);
        }
    }, [printing]);

    const onSSNClick = useCallback(
        (ssnState) => {
            let newSSNState = "TRUNCATED";

            if (ssnState === "NORMAL") newSSNState = "HIDDEN";
            else if (ssnState === "TRUNCATED") newSSNState = "NORMAL";

            if (ssnDisplay === ssnAccess.Partial && newSSNState === "NORMAL") {
                newSSNState = "HIDDEN";
            }

            setCurrentSSNState(newSSNState);
        },
        [ssnDisplay]
    );

    const { selectedNo, type, lastUpdatedId, selectedRecords } = useSelector(
        (state) => state.leaveClaimRecords
    );

    useEffect(() => {
        setEmployeesLastFetched(moment().format("MM/DD/YYYY hh:mm A"));
    }, [isLoading, lastUpdatedId.id]);

    useEffect(() => {
        return () => {
            dispatch(resetRelatedNos());
        };
    }, []);

    const initPrint = useCallback(() => {
        setPrinting(true);
    }, []);

    useEffect(() => {
        if (lastUpdatedId.id != "") {
            setTimeout(() => {
                const section = document.getElementById(lastUpdatedId.id);

                window.scrollTo({
                    top: section.offsetTop - 270,
                    behavior: "smooth",
                });
            }, [200]);
        }
    }, [lastUpdatedId]);

    const handleClaimHistoryData = useCallback((data) => {
        setClaimHistoryData(data);
    }, []);

    const [isFetchingLeave, setIsFetchingLeave] = useState(false);

    let fetchingLeave = (value) => {
        setIsFetchingLeave(value);
    };

    const [isFetchingClaim, setIsFetchingClaim] = useState(false);

    let fetchingClaim = (value) => {
        setIsFetchingClaim(value);
    };

    return (
        <ErrorBoundary>
            <div className="employeedet">
                <Breadcrumb
                    links={[
                        {
                            path: "/search",
                            name: "Search",
                        },
                        {
                            path: `/organization/${orgId}/employees/search`,
                            name: "Employee",
                        },
                        {
                            name: "Claim & Leave",
                        },
                    ]}
                />

                {isFetching && <Loader />}

                {!isUninitialized &&
                    !isFetching &&
                    !isError &&
                    employee &&
                    employee.EmployeeDetails === null && (
                        <NoRecords
                            page="claim & leave"
                            label="claim & leave"
                            attribute1="claim & leave"
                            attribute3="claim_error"
                        />
                    )}
                {isError && (
                    <RequestFailed
                        tabIndex={0}
                        message={
                            "Your search failed, please try again or contact us for further assistance."
                        }
                        label="claim & leave"
                        attribute1="claim & leave"
                        attribute3="claim_error"
                    />
                )}
                {employee?.EmployeeDetails != null && !isFetching && (
                    <>
                        <EmployeeRecord
                            employee={employee.EmployeeDetails}
                            isLoading={isLoading}
                            ssnDisplay={ssnDisplay}
                            ssnState={currentSSNState}
                            initPrint={initPrint}
                            onSSNClick={onSSNClick}
                            hideBanner={props.showInformationBanner}
                            isFetchingLeave={isFetchingLeave}
                            isFetchingClaim={isFetchingClaim}
                        />

                        <ClaimLeaveHistoryTable
                            handleLeaveNoSelection={handleLeaveNoSelection}
                            handleClaimNoSelection={handleClaimNoSelection}
                            handleClaimHistoryData={handleClaimHistoryData}
                            handleSelectedOrgId={handleSelectedOrgId}
                            employeeLeaves={employee.ClaimLeaveData}
                            isLoading={isLoading}
                        />
                    </>
                )}

                {!isFetching && (
                    <>
                        {type === recordsType.LEAVE && selectedNo !== null && (
                            <LeaveRecord
                                leaveNo={selectedNo}
                                employeeId={id}
                                organizationId={
                                    orgIdNo === null ? orgId : orgIdNo
                                }
                                organizationAccess={organizationsAccess[orgId]}
                                fetchingLeave={fetchingLeave}
                                user={user}
                            />
                        )}

                        {type === recordsType.CLAIM && selectedNo !== null && (
                            <ClaimRecord
                                claimNo={selectedNo}
                                employeeId={id}
                                organizationId={
                                    orgIdNo === null ? orgId : orgIdNo
                                }
                                organizationAccess={organizationsAccess[orgId]}
                                fetchingClaim={fetchingClaim}
                                user={user}
                            />
                        )}

                        {selectedRecords.map((selectedRecord, index) => {
                            if (selectedRecord.type === recordsType.LEAVE) {
                                return (
                                    <LeaveRecord
                                        key={index}
                                        leaveNo={selectedRecord.no}
                                        employeeId={id}
                                        organizationId={
                                            orgIdNo === null ? orgId : orgIdNo
                                        }
                                        organizationAccess={
                                            organizationsAccess[orgId]
                                        }
                                        fetchingLeave={fetchingLeave}
                                        user={user}
                                    />
                                );
                            } else if (
                                selectedRecord.type === recordsType.CLAIM
                            ) {
                                return (
                                    <ClaimRecord
                                        key={index}
                                        claimNo={selectedRecord.no}
                                        employeeId={id}
                                        organizationId={
                                            orgIdNo === null ? orgId : orgIdNo
                                        }
                                        organizationAccess={
                                            organizationsAccess[orgId]
                                        }
                                        fetchingClaim={fetchingClaim}
                                        user={user}
                                    />
                                );
                            }
                        })}
                    </>
                )}

                {!isLoading && <FooterNote date={employeesLastFetched} />}
            </div>

            <DataToPrint
                ref={componentRef}
                printing={printing}
                data={{
                    employee: employee,
                    ssnDisplay: ssnDisplay,
                    ssnState: currentSSNState,
                    employeeLeaves: employee ? employee.ClaimLeaveData : [],
                    employeesLastFetched: employeesLastFetched,
                    employeeId: id,
                    orgId: orgIdNo === null ? orgId : orgIdNo,
                    organizationsAccess: organizationsAccess,
                    claimHistoryData: claimHistoryData,
                }}
            />
        </ErrorBoundary>
    );
}

export default EmployeeClaimLeave;
