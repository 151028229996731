import React, { useRef, useState, useEffect } from "react";
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    addDays,
    isSameDay,
    parse,
    isValid,
    subDays,
} from "date-fns";
import Button from "./ui/Button";
import { changesReportType, WEEK_NAMES } from "../constants";
import CalendarIcon from "./icons/CalendarIcon";

const Calendar = ({
    startDate: propStartDate,
    endDate: propEndDate,
    handleConfirmButton,
    setShowReportingCalendar,
    reportViewType,
    minimumStartDate,
    maximumEndDate,
    setOpenReportingPeriod
}) => {

    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startInput, setStartInput] = useState("");
    const [endInput, setEndInput] = useState("");
    const [showCalendar, setShowCalendar] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [startError, setStartError] = useState(false);
    const [endError, setEndError] = useState(false);
    const [isCalenderClose, setIsCalenderClose] = useState(false);
    const yearDropdownRef = useRef(null);

    const dropdownItemsRef = useRef([]);

    const inputRefs = {
        start: useRef(null),
        end: useRef(null),
    };

    useEffect(() => {
        if (inputRefs.start.current && inputRefs.end.current) {
            inputRefs.start.current.addEventListener("blur", handleBlur);
            inputRefs.end.current.addEventListener("blur", handleBlur);
        }
        return () => {
            if (inputRefs.start.current) {
                inputRefs.start.current.removeEventListener("blur", handleBlur);
            }
            if (inputRefs.end.current) {
                inputRefs.end.current.removeEventListener("blur", handleBlur);
            }
        };
    }, []);

    const today = new Date();
    const validStartDate = new Date(minimumStartDate);
    const maxDate = maximumEndDate ? new Date(maximumEndDate) : today;
    const years = Array.from({ length: maxDate.getFullYear() - validStartDate.getFullYear() + 1 }, (_, i) => maxDate.getFullYear() - i);

    const validEndDate = today;

    useEffect(() => {
        if (startDate && startDate < validStartDate) {
            setStartDate(validStartDate);
            setStartInput(format(validStartDate, "MM/dd/yyyy"));
        }
        if (endDate && endDate > maxDate) {
            setEndDate(maxDate);
            setEndInput(format(maxDate, "MM/dd/yyyy"));
        }
    }, [startDate, endDate]);


    useEffect(() => {
        if (propStartDate && isValid(parse(propStartDate, "MM/dd/yyyy", new Date()))) {
            const parsedStartDate = parse(propStartDate, "MM/dd/yyyy", new Date());
            setStartDate(parsedStartDate);
            setStartInput(format(parsedStartDate, "MM/dd/yyyy"));
        }
        if (propEndDate && isValid(parse(propEndDate, "MM/dd/yyyy", new Date()))) {
            const parsedEndDate = parse(propEndDate, "MM/dd/yyyy", new Date());
            setEndDate(parsedEndDate);
            setEndInput(format(parsedEndDate, "MM/dd/yyyy"));
        }
        if (reportViewType === changesReportType && !propEndDate) {
            setEndDate(today); // 'today' is the current date
            setEndInput(format(today, "MM/dd/yyyy"));
        }
    }, [propStartDate, propEndDate, reportViewType]);

    const isEndDateDisabled = reportViewType === changesReportType;
    // Limit startDate and endDate based on reportingType
    useEffect(() => {
        if (startDate && startDate < validStartDate) {
            setStartDate(validStartDate);
            setStartInput(format(validStartDate, "MM/dd/yyyy"));
        }
        if (endDate && endDate > validEndDate) {
            setEndDate(validEndDate);
            setEndInput(format(validEndDate, "MM/dd/yyyy"));
        }
    }, [startDate, endDate, reportViewType]);

    const handleInputChange = (event, type) => {
        let value = event.target.value;
        // Ensure the correct format (MM/dd/yyyy)
        let cleanedValue = value.replace(/\D/g, "");
        let formattedValue = "";

        if (cleanedValue.length > 0) {
            const parts = value.split('/');
            formattedValue = parts[0] || '';

            if (cleanedValue.length >= 2) {
                formattedValue += '/' + (parts[1] || '');
            }

            if (cleanedValue.length >= 4) {
                formattedValue += '/' + (parts[2] || '');
            }
        }
        if (event.nativeEvent.inputType === "deleteContentBackward") {
            // Don't reapply formatting while deleting
            if (type === "start") {
                setStartInput(value);
                setStartError(false);
                setStartDate(null);
            } else if (type === "end") {
                setEndInput(value);
                setEndError(false);
                setEndDate(null);
            }
            return;
        }

        if (type === "start") {
            setStartInput(formattedValue);

            if (cleanedValue.length === 8) {
                const parsedStartDate = parse(formattedValue, "MM/dd/yyyy", new Date());

                if (
                    isValid(parsedStartDate) &&
                    parsedStartDate >= validStartDate &&
                    parsedStartDate <= validEndDate
                ) {
                    setStartDate(parsedStartDate);
                    setStartError(false);

                    if (endDate && parsedStartDate > endDate) {
                        setEndDate(parsedStartDate);
                        setEndInput(format(parsedStartDate, "MM/dd/yyyy"));
                    }
                    setTimeout(() => {
                        if (inputRefs.end.current) {
                            inputRefs.end.current.focus();
                        }
                    }, 0);
                } else {
                    setStartError(true);
                    setStartDate(null);
                }
            } else {
                setStartError(false);
                setStartDate(null);
            }
        } else if (type === "end") {
            setEndInput(formattedValue);

            if (cleanedValue.length === 8) {
                const parsedEndDate = parse(formattedValue, "MM/dd/yyyy", new Date());

                if (
                    isValid(parsedEndDate) &&
                    parsedEndDate >= validStartDate &&
                    parsedEndDate <= validEndDate
                ) {
                    if (startDate && parsedEndDate < startDate) {
                        setStartDate(parsedEndDate);
                        setStartInput(format(parsedEndDate, "MM/dd/yyyy"));
                        setEndDate(startDate);
                        setEndInput(format(startDate, "MM/dd/yyyy"));
                    } else {
                        setEndDate(parsedEndDate);
                    }
                    setEndError(false);
                } else {
                    setEndError(true);
                    setEndDate(null);
                }
            } else {
                setEndError(false);
                setEndDate(null);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (yearDropdownRef.current && !yearDropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleKeyDownDropdown = (e, index) => {
        switch (e.key) {
            case "ArrowDown":
                e.preventDefault();
                if (index < years.length - 1) {
                    dropdownItemsRef.current[index + 1].focus();
                }
                break;
            case "ArrowUp":
                e.preventDefault();
                if (index > 0) {
                    dropdownItemsRef.current[index - 1].focus();
                }
                break;
            case "Enter":
            case " ":
                e.preventDefault();
                handleYearSelection(years[index]);
                break;
            default:
                break;
        }
    };

    const renderHeader = () => (
        <div className="header">
            <div className="d-flex align-items-center month" >
                <i
                    className={`fa-solid fa-chevron-left cursor-pointer ${currentMonth.getFullYear() === years[years.length - 1] && currentMonth.getMonth() === 0 ? "disabled" : ""}`}
                    onClick={() => handleMonthChange("previous")}
                    tabIndex={currentMonth.getFullYear() === years[years.length - 1] && currentMonth.getMonth() === 0 ? -1 : 0}
                    role="button"
                    aria-label={`Previous month, ${format(subMonths(currentMonth, 1), "MMMM")}`}
                    onKeyDown={(e) => handleKeyDown(e, "previous")}
                    aria-live="assertive"
                    style={{
                        pointerEvents: currentMonth.getFullYear() === years[years.length - 1] && currentMonth.getMonth() === 0 ? "none" : "auto",
                        opacity: currentMonth.getFullYear() === years[years.length - 1] && currentMonth.getMonth() === 0 ? 0.5 : 1
                    }}
                ></i>
                <div id="currentMonth">{format(currentMonth, "MMMM")}</div>
                <i
                    className={`fa-solid fa-chevron-right cursor-pointer ${currentMonth.getFullYear() === years[0] && currentMonth.getMonth() === new Date().getMonth() ? "disabled" : ""}`}
                    onClick={() => handleMonthChange("next")}
                    tabIndex={currentMonth.getFullYear() === years[0] && currentMonth.getMonth() === new Date().getMonth() ? -1 : 0}
                    role="button"
                    aria-label={`Next month, ${format(addMonths(currentMonth, 1), "MMMM")}`}
                    onKeyDown={(e) => handleKeyDown(e, "next")}
                    style={{
                        pointerEvents: currentMonth.getFullYear() === years[0] && currentMonth.getMonth() === new Date().getMonth() ? "none" : "auto",
                        opacity: currentMonth.getFullYear() === years[0] && currentMonth.getMonth() === new Date().getMonth() ? 0.5 : 1
                    }}
                ></i>

            </div>

            <div className="year-dropdown" onClick={() => setIsOpen(!isOpen)} ref={yearDropdownRef} id="year-listbox"
                role="listbox" tabIndex={0} aria-label="year dropdown" aria-expanded={isOpen}
                onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                        setIsOpen(!isOpen)
                    } else if (e.key === "Escape" && isOpen) {
                        setIsOpen(false);  // Close the calendar on Escape
                        e.stopPropagation();  // Stop propagation to prevent other handlers
                        e.preventDefault();  // Prevent default behavior
                    }
                }}
            >
                {currentMonth.getFullYear()}
                <i className={`fa-solid ${isOpen ? "fa-caret-up" : "fa-caret-down"}`}></i>

                <ul className={`section-card custom-scrollbar ${isOpen ? "yearCalenderDropdownOpen" : "yearCalenderDropdownClose"}`} aria-label="Select a year">
                    {years.map((year, index) => (
                        <li
                            key={year}
                            ref={(el) => (dropdownItemsRef.current[index] = el)}
                            role="option"
                            onClick={() => handleYearSelection(year)}
                            className={year === currentMonth.getFullYear() ? "year-selected" : ""}
                            tabIndex={isOpen ? 0 : -1}
                            onKeyDown={(e) => handleKeyDownDropdown(e, index)}
                            aria-selected={year === currentMonth.getFullYear()}
                        >
                            {year}
                        </li>
                    ))}
                </ul>
            </div>

            <div aria-live="polite" className="sr-only">
                {isOpen
                    ? "Year dropdown expanded."
                    : "Year dropdown collapsed."}
                Currently showing year {currentMonth.getFullYear()}.
            </div>
        </div>
    );

    // Event Handlers
    const handleMonthChange = (direction) => {
        const newMonth =
            direction === "previous"
                ? subMonths(currentMonth, 1)
                : addMonths(currentMonth, 1);
        setCurrentMonth(newMonth);
    };

    const handleKeyDown = (e, direction) => {
        if (e.key === "Enter" || e.key === " ") {
            handleMonthChange(direction);
            e.preventDefault();
        }
    };

    const handleYearSelection = (year) => {
        setCurrentMonth(new Date(year, currentMonth.getMonth()));
    };
    const renderDays = () => {
        const days = [];
        const startDate = startOfWeek(new Date());
        for (let i = 0; i < 7; i++) {
            const day = format(addDays(startDate, i), "EEEEE");  // This gets the short day name (e.g., "S" for Sunday)
            const ariaLabel = WEEK_NAMES[i].toLowerCase();

            days.push(
                <div
                    className="col col-center"
                    style={{ position: "relative" }}
                    key={i}
                    aria-label={ariaLabel} role="columnheader"
                    tabIndex="0"
                    aria-live="polite"
                >
                    <span style={{
                        position: "absolute",
                        height: 0,
                        width: 0,
                        overflow: "hidden",
                    }}>{ariaLabel}</span>
                    <span aria-hidden="true">{day}</span>
                </div>
            );
        }

        return <div
            className="days row"
            role="row"
            aria-labelledby="weekdays"
        >
            {days}
        </div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const today = new Date();

        const rows = [];
        let days = [];
        let day = startDate;

        while (day <= monthEnd || days.length > 0) {
            for (let i = 0; i < 7; i++) {
                const cloneDay = day;
                const isDisabled = day < validStartDate || day > validEndDate;
                const isToday = isSameDay(cloneDay, today); // Check if the current day is today

                if (day < monthStart || day > monthEnd) {
                    days.push(<div className="col cell empty" key={day} role="cell"></div>);
                } else {

                    days.push(
                        <div
                            className={`col ${isSameDay(day, selectedDate)
                                ? "date-selected"
                                : ""
                                } ${isToday ? "today" : ""} ${isDisabled ? "disabled" : ""
                                }`}
                            key={cloneDay}
                            role="cell"
                            style={{
                                width: 36,
                                height: 36,
                                paddingTop: 4,
                                pointerEvents: isDisabled ? "none" : "auto",
                                opacity: isDisabled ? 0.5 : 1,
                            }}
                        >
                            <div role="button"
                                onClick={() =>
                                    !isDisabled && handleDateClick(cloneDay)
                                }
                                onKeyDown={(e) => {
                                    if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
                                        handleDateClick(cloneDay)
                                    }
                                }}
                                tabIndex={0}
                                aria-label={format(cloneDay, "dd")}  // More descriptive label for screen readers
                                aria-disabled={isDisabled}
                                aria-selected={isSameDay(day, selectedDate) ? true : false}
                            >
                                <span>{format(cloneDay, "d")}</span>
                            </div>
                        </div>
                    );
                }
                day = addDays(day, 1);
            }

            rows.push(
                <div className="row" style={{ height: 36 }} key={day} role="row">
                    {days}
                </div>
            );
            days = [];
        }

        return (
            <div className="dates">
                {rows}
            </div>
        );
    };

    const handleDateClick = (clickedDate) => {
        if (reportViewType === changesReportType) {
            // Set only the startDate; retain the default endDate
            setStartDate(clickedDate);
            setStartInput(format(clickedDate, "MM/dd/yyyy"));
        } else {
            // Handle start and end date selection for other report types
            if (!startDate || (startDate && endDate)) {
                setStartDate(clickedDate);
                setEndDate(null);
                setStartInput(format(clickedDate, "MM/dd/yyyy"));
                setEndInput("");
                setSelectedDate(clickedDate);
            } else {
                const sortedDates = [startDate, clickedDate].sort((a, b) => a - b);
                setStartDate(sortedDates[0]);
                setEndDate(sortedDates[1]);
                setStartInput(format(sortedDates[0], "MM/dd/yyyy"));
                setEndInput(format(sortedDates[1], "MM/dd/yyyy"));
                setSelectedDate(clickedDate);
            }
        }
        // Reset calendar to the current month
        setCurrentMonth(new Date());
        setSelectedDate(new Date());
    };

    useEffect(() => {
        if (reportViewType === changesReportType) {
            setCurrentMonth(new Date());
            setEndDate(new Date());
        }
    }, [reportViewType])

    const [focusField, setFocusField] = useState(null);

    const handleFocus = (field) => {
        setFocusField(field);
    };

    const handleBlur = () => {
        setFocusField(null);
    };
    const handleClose = () => {
        setIsCalenderClose(true);
        setTimeout(() => {
            setShowCalendar(false);
            setIsCalenderClose(false);
        }, 300); // Match the animation duration
    };
    const handleCalendarIconClick = (e, field) => {
        e.stopPropagation();
        handleFocus(field);

        if (field === "start" || (field === "end" && !isEndDateDisabled)) {
            if (showCalendar) {
                handleClose();
            } else {
                setShowCalendar(true);
            }
        }
    };
    useEffect(() => {
        if (startDate && endDate) {
            handleClose();
        }
    }, [endDate, startDate]);

    useEffect(() => {
        if (startDate && reportViewType !== changesReportType) {
            handleFocus("end");
        } else {
            handleFocus("start");
        }
        if (startDate && endDate) {
            handleFocus("start");
        }
    }, [endDate, reportViewType, startDate])
    useEffect(() => {
        if (showCalendar) {
            // Give focus to the calendar container
            document.getElementById("calendar-container").focus();
        }
    }, [showCalendar]);



    return (
        <div className="section-card custom-calendar"
        >
            <div className="calendar-header">
                <div>
                    <i
                        className="fa-solid fa-chevron-left"
                        style={{ color: "#037CB7", cursor: "pointer" }}
                        tabIndex={0}
                        role="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setOpenReportingPeriod(true);
                            setShowReportingCalendar(false);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setOpenReportingPeriod(true);
                                setShowReportingCalendar(false);
                                e.stopPropagation();  // Stop propagation to prevent other handlers
                                e.preventDefault();  // Prevent default behavior
                            }
                        }}
                    ></i>
                </div>
                Date range
            </div>
            <div className="date-inputs">
                <div className="date-input">
                    <label>From</label>
                    <div className="input-box">
                        <input
                            type="text"
                            ref={inputRefs.start}
                            value={startInput}
                            onChange={(e) => {
                                handleInputChange(e, "start");
                                handleFocus("start");
                            }}
                            aria-label="start date"
                            placeholder="MM/DD/YYYY"
                            maxLength={10}
                            onFocus={() => handleFocus("start")}
                            onBlur={handleBlur}
                            style={{
                                border: startError ? "2px solid red" : focusField === "start" ? "2px solid #037CB7" : "",
                                backgroundColor: focusField === "start" ? "#E6F2F8" : "",
                            }}
                        />
                        <div className="icon-inside-input" onClick={(e) => handleCalendarIconClick(e, "start")} tabIndex={0}
                            aria-label="start date calendar icon"
                            onKeyDown={(e) => {
                                if (
                                    (e.key === "Enter" || e.key === " ")
                                ) {
                                    handleCalendarIconClick(e, "start");
                                    e.preventDefault();
                                }
                            }}
                        >
                            <CalendarIcon />
                        </div>
                    </div>
                </div>
                <div className="date-input" >
                    <label>To</label>
                    <div className="input-box">
                        <input
                            type="text"
                            ref={inputRefs.end}
                            value={endInput}
                            onChange={(e) => {
                                handleInputChange(e, "end");
                                handleFocus("end");
                            }}
                            placeholder="MM/DD/YYYY"
                            maxLength={10}
                            aria-label="end date"
                            disabled={!startDate || isEndDateDisabled}
                            onFocus={() => handleFocus("end")}
                            onBlur={handleBlur}
                            style={{
                                border: endError ? "2px solid red" : focusField === "end" ? "2px solid #037CB7" : "",
                                backgroundColor: focusField === "end" ? "#E6F2F8" : "",
                            }}
                        />
                        <div className="icon-inside-input" style={isEndDateDisabled ? { pointerEvents: "none", opacity: "0.4" } : {}} onClick={(e) => handleCalendarIconClick(e, "end")}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (
                                    (e.key === "Enter" || e.key === " ")
                                ) {
                                    handleCalendarIconClick(e, "end");
                                    e.preventDefault();
                                }
                            }}
                            aria-label="end date calendar icon"
                        >
                            <CalendarIcon color={!startDate || isEndDateDisabled ? "#cdcdcd" : ""} />
                        </div>

                    </div>
                </div>
            </div>
            {
                showCalendar && (
                    <div
                        className={`calendar-dropdown ${isCalenderClose ? "hide" : ""} ${focusField === "end" ? "slide-calender" : ""}`}
                        style={{ position: "relative" }}
                    >
                        <div
                            id="calendar-container"
                            role="combobox"
                            tabIndex={0}
                            aria-live="polite"
                            style={{
                                position: "absolute",
                                height: 0,
                                width: 0,
                                overflow: "hidden",
                            }}
                        >
                            {(showCalendar && focusField === "start") && "Select start date" || (showCalendar && focusField === "end") && "Select end date"}
                        </div>
                        {renderHeader()}
                        <div role="table">
                            {renderDays()}
                            {renderCells()}
                        </div>
                    </div>
                )
            }
            <div
                className="fs-16 d-flex"
                style={{
                    alignSelf: "end",
                    marginTop: 16,
                    paddingRight: 8,
                    width: 94,
                }}
            >
                <div
                    role="status"
                    aria-live="assertive"
                    style={{ position: "absolute", width: 0, height: 0, overflow: "hidden" }}
                >
                    {(startDate && endDate) && `Selected Date range from ${format(startDate, "MM/dd/yyyy")} to ${format(endDate, "MM/dd/yyyy")}`}
                </div>
                <Button
                    text="Confirm"
                    onClick={() => {
                        if (startDate && endDate) {
                            handleConfirmButton({
                                startDate: format(startDate, "MM/dd/yyyy"),
                                endDate: format(endDate, "MM/dd/yyyy"),
                            });
                            setShowCalendar(false);
                            setShowReportingCalendar(false);
                        }
                    }}
                    disabled={!startDate || !endDate}
                    aria-label="Confirm"
                />
            </div>
        </div >
    );
};

export default Calendar;
