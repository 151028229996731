import React, { useState, useRef, useEffect } from "react";

const MultiSelect = (props) => {
    const {
        selectedOptions,
        setSelectedOptions,
        options,
        headerLabel,
        setEnableReset,
        setEnableApplyButton,
        handleGtmTags,
        gtmTagLabel,
        anableSearch,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const dropdownRef = useRef(null);
    const toggleRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (!isOpen) setFocusedIndex(0); // Reset focus to the first option (Select All)
    };

    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const isAllSelected = filteredOptions.length > 0 ? options.every((option) =>
        selectedOptions.some((selected) => selected.value === option.value)
    ) : null;

    const handleOptionToggle = (index) => {
        if (index === 0) {
            isAllSelected ? handleUnselectAll() : handleSelectAll();
        } else {
            const option = filteredOptions[index - 1];
            setSelectedOptions((prev) => {
                const isSelected = prev.some((selected) => selected.value === option.value);
                return isSelected
                    ? prev.filter((selected) => selected.value !== option.value)
                    : [...prev, option];
            });
            setEnableReset(true);
            setEnableApplyButton(true);
            if (gtmTagLabel) handleGtmTags(gtmTagLabel);
        }
    };

    const handleSelectAll = () => {
        setSelectedOptions(filteredOptions);
        setEnableReset(true);
        setEnableApplyButton(true);
        if (gtmTagLabel) handleGtmTags(gtmTagLabel);
    };

    const handleUnselectAll = () => {
        setSelectedOptions([]);
        setEnableReset(true);
        setEnableApplyButton(true);
    };
    useEffect(() => {
        const focusedElement = document.getElementById(`option-${focusedIndex}`);
        if (focusedElement) focusedElement.focus();
    }, [isOpen, focusedIndex]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault();

        if (isOpen) {
            switch (e.key) {
                case "ArrowDown":
                    setFocusedIndex((prev) => Math.min(filteredOptions.length, prev + 1));
                    break;
                case "ArrowUp":
                    setFocusedIndex((prev) => Math.max(0, prev - 1));
                    break;
                case "Enter":
                case " ":
                    handleOptionToggle(focusedIndex);
                    break;
                case "Escape":
                    setIsOpen(false);
                    break;
                default:
                    break;
            }
        } else if (e.key === "ArrowDown" || e.key === "Enter" || e.key === " ") {
            setIsOpen(true);
            setFocusedIndex(0);
        }
    };
    useEffect(() => {
        if (isOpen) {
            const focusableElements = dropdownRef.current.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleFocusTrap = (e) => {
                if (e.key === "Tab") {
                    if (e.shiftKey) {
                        // Shift + Tab
                        if (document.activeElement === firstElement) {
                            e.preventDefault();
                            lastElement.focus(); // Move focus to the last element
                        }
                    } else {
                        // Tab
                        if (document.activeElement === lastElement) {
                            e.preventDefault();
                            firstElement.focus(); // Move focus to the first element
                        }
                    }
                } else if (e.key === "Escape") {
                    setIsOpen(false); // Close on Escape
                }
            };

            document.addEventListener("keydown", handleFocusTrap);

            return () => {
                document.removeEventListener("keydown", handleFocusTrap);
            };
        }
    }, [isOpen]);


    const placeholderText =
        isAllSelected
            ? "All items are selected."
            : selectedOptions.length > 0
                ? selectedOptions.map((item) => item.label).join(", ")
                : "Add filters +";

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !toggleRef.current.contains(event.target)
            ) {
                setIsOpen(false);
                setSearchQuery("");
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const dropdownStyle = placeholderText === "Add filters +" ? { color: "#aaa" } : { color: "#fff" };

    const handleOptionFocus = (optionLabel, isSelected) => {
        const status = isSelected ? "checked" : "unchecked";
        const announcement = `${optionLabel} ${status}`;
        const liveRegion = document.getElementById("aria-live-region");
        if (liveRegion) {
            liveRegion.textContent = announcement;
            setTimeout(() => {
                liveRegion.textContent = "";
            }, 1000);
        }
    };

    return (
        <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print" style={{ position: "relative" }}>
            <label className="header pb-0" htmlFor={`${headerLabel}`}>{headerLabel}</label>
            <div
                ref={toggleRef}
                className="d-flex justify-content-between cursor-pointer customFilterSection"
                onClick={toggleDropdown}
                onKeyDown={handleKeyDown}
                role="combobox"
                aria-expanded={isOpen}
                aria-controls="multi-select-dropdown"
                aria-label={headerLabel}
                tabIndex={0}
                aria-activedescendant={`option-${focusedIndex}`}
                id={`${headerLabel}`}
            >
                <span id={headerLabel} className="sr-only">
                    Use the arrow keys to navigate options, Enter to select, and Escape to close.
                </span>
                <div className="customFilterDropDown" style={dropdownStyle}>{placeholderText}</div>
                <div className="d-flex" style={{ fontSize: 17, paddingLeft: 8, paddingRight: 8, gap: 8 }}>
                    {selectedOptions.length > 0 && (
                        <i
                            className="fa-solid fa-times"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleUnselectAll();
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    e.preventDefault();
                                    handleUnselectAll([]);
                                }
                            }}
                            role="button"
                            tabIndex={0}
                            aria-label="Clear selection"
                        ></i>
                    )}
                    <i className={`fa-solid fa-angle-${isOpen ? "up" : "down"}`}></i>
                </div>
            </div>
            {isOpen && (
                <div className="custom-dropdown-container">
                    <div
                        ref={dropdownRef}
                        id="multi-select-dropdown"
                        className="section-card custom-section-dropdown"
                        role="listbox"
                        aria-labelledby="multi-select-label"
                        onKeyDown={handleKeyDown}
                        aria-live="assertive"
                        style={{ paddingTop: 0 }}
                    >
                        {anableSearch && (
                            <div className="search-box-container">
                                <input
                                    type="text"
                                    className="search-box"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        )}
                        <div className="custom-scrollbar" style={{ maxHeight: 250, borderTop: anableSearch ? "1px solid #cdcdcd" : 0 }}>
                            {
                                filteredOptions.length > 0 &&
                                <div
                                    className={`multi-select-option ${focusedIndex === 0 ? "focused" : ""}`}
                                    onClick={() => handleOptionToggle(0)}
                                    role="option"
                                    aria-checked={isAllSelected}
                                    id="option-0"
                                    onFocus={() => handleOptionFocus("Select All", isAllSelected)}
                                    tabIndex={-1}
                                >
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        checked={isAllSelected}
                                        onChange={() => handleOptionToggle(0)}
                                        aria-hidden="true"
                                        tabindex="-1"
                                    />
                                    <label style={{ marginLeft: 8 }}>Select All</label>
                                </div>
                            }

                            {filteredOptions.length === 0 ? (
                                <div className="d-flex justify-content-center my-8p text-center">No options</div>
                            ) : (
                                filteredOptions.map((option, index) => (
                                    <div
                                        key={option.value}
                                        className={`multi-select-option ${focusedIndex === index + 1 ? "focused" : ""}`}
                                        onClick={() => handleOptionToggle(index + 1)}
                                        role="option"
                                        aria-checked={selectedOptions.some((selected) => selected.value === option.value)}
                                        id={`option-${index + 1}`}
                                        tabIndex={-1}
                                    >
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            checked={selectedOptions.some((selected) => selected.value === option.value)}
                                            onClick={(e) => e.stopPropagation()}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" || e.key === " ") {
                                                    e.preventDefault();
                                                    handleOptionToggle(index + 1);
                                                }
                                            }}
                                            onChange={() => handleOptionToggle(index + 1)}
                                            aria-checked={selectedOptions.some((selected) => selected.value === option.value) ? "true" : "false"}
                                            aria-hidden="true"
                                            tabindex="-1"
                                            role="option"
                                        />
                                        <label style={{ marginLeft: 8 }}>{option.label}</label>
                                    </div>


                                ))
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div id="aria-live-region" style={{ position: "absolute", left: "-9999px" }} aria-live="polite"></div>
        </div>
    );
};

export default MultiSelect;
