import React, { useEffect, useState } from "react";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import ReportFilters from "./ReportFilters";
import { paymentHistoryType } from "../../constants";

function ReportViewFilter({
    organizationName,
    userSelectedOrganizationReports,
    reportId,
    relatedOrganizationNames,
    isFetching,
    title,
    coverageType,
    allowedPoliciesAndDivisions,
    handleApplyFilter,
    selectedFilters,
    coverageTypeOptions,
    reportingPeriod,
    status,
    reportingGroup,
    reportRanDate,
    reportViewType,
    defaultReportingPeriod,
    defaultReportingStatus,
    customDateRange,
    handleDateRangeChange,
    selectedCoverageType,
    setSelectedCoverageType,
    selectedStatus,
    selectedPolicyDivision,
    handlePolicyDivision,
    initialPolicyDivision,
    selectedReportingGroup,
    handleReportingGroup,
    includeHistoricalLeave,
    periodScheduleType,
    periodStatus,
    protectionStatus,
    includeHistoricalLeaveValue,
    periodScheduleTypeValue,
    protectionStatusValue,
    periodStatusValue,
    onIncludeHistoricalValueChange,
    onReportingPeriodChange,
    onHandleReset,
    enableReset,
    enableApply,
    onHandleOutsideClick,
    disableConfirm,
    claimData,
    totalPages,
    pagecounter,
    setEnableReset,
    setEnableApplyButton,
    setSelectedStatus,
    setPeriodScheduleTypeValue,
    setPeriodStatusValue,
    setProtectionStatusValue,
    setSelectedReportingGroup,
    isError,
    isFetchLoading
}) {

    const [isfetchingMoreData, setIsFectchingMoreDate] = useState(false);

    useEffect(() => {
        setIsFectchingMoreDate(isFetchLoading)
    }, [isFetchLoading]);

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        path: "/reportingcapabilities",
                        name: "Reports",
                    },
                    {
                        name: title === paymentHistoryType ? "Payment history" : title,
                    },
                ]}
            />

            <section
                className="section-card container-heading data-view-card"
                style={
                    (isFetching || isfetchingMoreData) && !isError ? { pointerEvents: "none", opacity: "0.4" } : {}
                }
            >
                <div
                    className="row"
                    style={{ position: "relative", rowGap: 16 }}
                >
                    <ReportFilters
                        coverageType={coverageType}
                        allowedPoliciesAndDivisions={
                            allowedPoliciesAndDivisions
                        }
                        handleApplyFilter={handleApplyFilter}
                        selectedFilters={selectedFilters}
                        coverageTypeOptions={coverageTypeOptions}
                        reportingPeriod={reportingPeriod}
                        status={status}
                        reportingGroup={reportingGroup}
                        reportRanDate={reportRanDate}
                        reportViewType={reportViewType}
                        defaultReportingPeriod={defaultReportingPeriod}
                        defaultReportingStatus={defaultReportingStatus}
                        handleDateRangeChange={handleDateRangeChange}
                        organizationName={organizationName}
                        userSelectedOrganizationReports={
                            userSelectedOrganizationReports
                        }
                        reportId={reportId}
                        relatedOrganizationNames={relatedOrganizationNames}
                        selectedCoverageType={selectedCoverageType}
                        setEnableReset={setEnableReset}
                        setEnableApplyButton={setEnableApplyButton}
                        setSelectedCoverageType={setSelectedCoverageType}
                        selectedStatus={selectedStatus}
                        setSelectedStatus={setSelectedStatus}
                        selectedPolicyDivision={selectedPolicyDivision}
                        handlePolicyDivision={handlePolicyDivision}
                        initialPolicyDivision={initialPolicyDivision}
                        selectedReportingGroup={selectedReportingGroup}
                        handleReportingGroup={handleReportingGroup}
                        includeHistoricalLeave={includeHistoricalLeave}
                        periodScheduleType={periodScheduleType}
                        periodStatus={periodStatus}
                        protectionStatus={protectionStatus}
                        defaultIncludeHistoricalLeaveValue={
                            includeHistoricalLeaveValue
                        }
                        defaultPeriodScheduleTypeValue={periodScheduleTypeValue}
                        defaultPeriodStatusValue={periodStatusValue}
                        defaultProtectionStatusValue={protectionStatusValue}
                        onIncludeHistoricalValueChange={
                            onIncludeHistoricalValueChange
                        }
                        onReportingPeriodChange={onReportingPeriodChange}
                        onHandleReset={onHandleReset}
                        enableReset={enableReset}
                        enableApply={enableApply}
                        onHandleOutsideClick={onHandleOutsideClick}
                        disableConfirm={disableConfirm}
                        claimData={claimData}
                        setPeriodScheduleTypeValue={setPeriodScheduleTypeValue}
                        setPeriodStatusValue={setPeriodStatusValue}
                        setProtectionStatusValue={setProtectionStatusValue}
                        setSelectedReportingGroup={setSelectedReportingGroup}
                    />
                </div>
            </section>
        </>
    );
}

export default ReportViewFilter;
