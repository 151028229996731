import { createEnum } from "../utils/helpers";
import COSTCO_LOGO from "../assets/logo/Costco_Wholesale_logo.svg";

// Datatable
export const paginationOptions = [5, 10, 50, 100, 200];
export const defaultPaginationItems = 10;

export const cacheDataForInSeconds = 300;

export const recordsType = createEnum(["LEAVE", "CLAIM"]);

export const ssnAccess = createEnum(["No Access", "Full Access", "Partial"]);

export const noDataPlaceholder = "—";

export const displayLogo = /organization\/[^\/]+\/employees\/[^\/]+/g;

const logos = {
    costco: COSTCO_LOGO,
};

export default logos;

export const dateFormat = "MM/DD/YYYY";
export const ownerTypeOrder = ["Personal", "Shared", "Standard"];
export const dataViewTypeOrder = [
    "Disability",
    "Leave",
    "Absence",
    "Payments",
    "Life",
    "Changes",
];
export const absenceType = "Absence";
export const paymentHistoryType = "PaymentHistory";
export const leaveType = "Leave";
export const disabilityType = "Disability";
export const lifeType = "Life";
export const changesReportType = "Changes";

export const currencyColumns = [
    "Payment Amount",
    "Federal Withholding",
    "Dental Insurance Premium",
    "Employee SS Tax",
    "Federal Withholding",
    "Gross Benefit in Period",
    "Local Withholding",
    "Medicare Tax",
    "Overpayment Reduction",
    "Post-Tax Total",
    "Pre-tax Medical Insurance",
    "Pre-Tax Total",
    "SS Offset",
    "State Offset",
    "State Withholding",
    "WC Offset",
    "Monthly Salary",
    "All Other Offsets",
    "SS Offset Amount",
    "Workers Compensation Offset",
    "FICA Amount",
    "Health Care Credit",
    "Health Care Credit To Date",
    "State Disability Offset Amount",
    "Paid to Date Amount",
    "Claimant Check Amount",
    "Gross Benefit Amount",
    "Deductions",
    "Offsets",
    "Weekly Salary",
];


export const WEEK_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];