import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
} from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";
import Pagination from "../../components/table/Pagination";
import sortIcon from "../../assets/icons/sort-icon.svg";
import filterIcon from "../../assets/icons/filter-icon.svg";
import ModifyViewButton from "./ModifyViewButton";
import NoRecords from "../../components/alerts/NoRecords";
import RequestFailed from "../../components/alerts/RequestFailed";
import Loader from "../../components/ui/Loader";
import ReportSavingPage from "./ReportSavingPage";
import ReportEditPage from "./ReportEditPage";
import { useNavigate } from "react-router-dom";
import GTM from "../../GTM";
import Divider from "../../components/ui/Divider";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
    absenceType,
    leaveType,
    paymentHistoryType,
    disabilityType,
    changesReportType
} from "../../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import ReportButtons from "../../components/ui/ReportButtons";
import Button from "../../components/ui/Button";
import ReactDOM from 'react-dom';
import { useSelector } from "react-redux";

function ReportTable({
    claimData,
    defaultColumns,
    isError,
    isFetching,
    isUninitialized,
    handleOptionChange,
    allViewsData,
    handleViewReportSave,
    loaderStatus,
    handleViewReportUpdate,
    handleCoverageType,
    selectedReportView,
    notificationStatus,
    description,
    selectedViewId,
    handleViewID,
    reportViewType,
    organizationsAccess,
    emitFiltersDataForSelectedView,
    selectedFilterStatus,
    reportSortingColumnDirection,
    reportSortingColumnName,
    reportSortingColumnAccessorKey,
    totalResults,
    pagecounter,
    handlePageSelect,
    isDataFormattingComplete,
    getPageCount,
    getPagePerItems,
    getSortDeitals,
    isFetchLoading,
    handleColumnFilter,
    columnFilter,
    setClearColumnFilter,
    filteredDataLength,
    originalDataLength,
    handleExportData,
    handlecolumnVisibility,
    columnVisibility,
    handleSsnDisplayMode,
    ssnDisplayMode,
    handleSelectedReport,
    selectedReport
}) {
    const [apikey, setApiKey] = useState({});
    const navigate = useNavigate();
    const gtm = new GTM(null);
    const firstInteractiveElementRef = useRef(null);
    const [checkColumnOrder, setCheckColumnOrder] = useState([]);
    const [columnOrderChange, setColumnOrderChange] = useState(true);
    const [showEditPage, setShowEditPage] = useState(false);
    const [sortingColumnDetails, setSortingColumnDetails] = useState();
    const [reportViewDropDownValues, setReportViewDropDownValues] = useState(
        []
    );
    const [updateViewDetails, setUpdateViewDetails] = useState([]);
    const [selectedViewDataType, setSelectedViewDataType] =
        useState(reportViewType);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [columnResizeMode, setColumnResizeMode] = useState("onChange");

    const { allPagingCounts } = useFlags();
    const [pagingCount, SetPagingCount] = useState(null);


    useEffect(() => {
        if (reportViewType === leaveType) {
            SetPagingCount(allPagingCounts?.leave);
        }
        if (reportViewType === disabilityType) {
            SetPagingCount(allPagingCounts?.claim);
        }
        if (reportViewType === changesReportType) {
            SetPagingCount(allPagingCounts?.changes);

        }
        if (reportViewType === paymentHistoryType) {
            SetPagingCount(allPagingCounts?.payments);
        }
        if (reportViewType === absenceType) {
            SetPagingCount(allPagingCounts?.claim);
        }
    }, [allPagingCounts]);

    const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
        setColumnOrderChange(false);
        columnOrder.splice(
            columnOrder.indexOf(targetColumnId),
            0,
            columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
        );
        return [...columnOrder];
    };

    const [columns, setColumns] = useState([...defaultColumns]);

    // Memoize the filter popup rendering
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isPopupPosition, setIsPopupPosition] = useState({ top: 0, left: 0 })
    const [filterValue, setFilterValue] = useState(null);
    const [accessorKey, setAccessorKey] = useState('');
    const [columnName, setColumnName] = useState('');

    const popupContainerRef = useRef(null);

    const closePopup = useCallback(() => setPopupVisible(false), []);

    const handleFilterChange = useCallback((e) => {
        e.preventDefault();
        const newValue = e.target.value;
        setFilterValue(newValue);
    }, []);

    const confirmFilter = useCallback(() => {
        handleColumnFilter({
            filterValue: filterValue,
            accessorKey: accessorKey,
            columnName: columnName
        });
        setPopupVisible(false)
        handlePageNumberClick(1)
    }, [filterValue, accessorKey, handleColumnFilter]);

    const [sorting, setSorting] = useState({ id: null, desc: false });


    const DraggableColumnHeader = ({
        header,
        table,
    }) => {
        const { getState, setColumnOrder } = table;
        const { columnOrder } = getState();
        setCheckColumnOrder(columnOrder);

        const { column } = header;

        const isColumnFiltered = columnFilter.some(
            (filter) => filter.columnName === header.column.columnDef.header
        );

        const [{ isDragging }, dragRef] = useDrag({
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
            item: () => column,
            type: "column",
        });


        const [, dropRef] = useDrop({
            accept: "column",
            drop: (draggedColumn) => {
                const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
                setColumnOrder(newColumnOrder);
                if (newColumnOrder[0] === columnOrder[columnOrder.length - 1]) scrollToHorizontal();
            },
        });
        // Memoize the SSN visibility toggle function to avoid unnecessary re-renders
        const toggleSSNVisibility = useCallback(() => {
            if (organizationsAccess.SSNACCESS === "Partial") {
                handleSsnDisplayMode((ssnDisplayMode + 1) % 2);
            }
            if (organizationsAccess.SSNACCESS === "Full") {
                handleSsnDisplayMode((ssnDisplayMode + 1) % 3);
            }
        }, [organizationsAccess.SSNACCESS, ssnDisplayMode, handleSsnDisplayMode]);

        // Memoize the function for scrolling to the right
        const scrollToHorizontal = useCallback(() => {
            const tableContainer = document.querySelector(".custom-table-container");
            if (tableContainer) {
                tableContainer.scrollTo({
                    left: tableContainer.scrollWidth - tableContainer.clientWidth,
                    behavior: "smooth",
                });
            }
        }, []);

        // Memoize the SSN icon rendering based on the access mode
        const getSSNIcon = () => {
            const icons = {
                Partial: [
                    <i className="fa fa-eye mask-eye cursor-pointer ml-4p" style={{ color: "#037cb7" }} aria-label="Mask SSN" />,
                    <i className="fa fa-eye-slash full-eye cursor-pointer ml-4p" aria-hidden="true" aria-label="Hide SSN" />,
                ],
                Full: [
                    <i className="fa fa-eye part-eye cursor-pointer report-ssn-icon ml-4p" style={{ color: "#037cb7" }} aria-label="Show partial SSN" />,
                    <i className="fa fa-eye mask-eye cursor-pointer ml-4p" aria-label="Mask SSN" />,
                    <i className="fa fa-eye-slash full-eye cursor-pointer ml-4p" style={{ color: "#037cb7" }} aria-hidden="true" aria-label="Hide SSN" />,
                ],
            };
            return icons[organizationsAccess?.SSNACCESS]?.[ssnDisplayMode] || null;
        };


        const togglePopup = useCallback((accessorKey, columnName, event) => {
            const iconRect = event.target.getBoundingClientRect();
            setPopupVisible(true);
            setAccessorKey(accessorKey);
            setColumnName(columnName);
            setIsPopupPosition({
                top: iconRect.bottom + window.scrollY - 100, // Position below the icon
                left: iconRect.left + window.scrollX
            })
            // Find the filter value for the selected column
            const existingFilter = columnFilter.find(
                (filter) => filter.accessorKey === accessorKey
            );

            // Prefill the filter value if it exists, otherwise set it to an empty string
            setFilterValue(existingFilter?.filterValue || '');
        }, [columnFilter]);

        const FilterIcon = useMemo(() => (
            <div
                style={{ marginLeft: '2px', cursor: 'pointer', position: "relative" }}
                ref={popupContainerRef}
                onClick={(e) => togglePopup(header.column.columnDef.accessorKey, header.column.columnDef.header, e)}
                tabIndex={0}
                role="button"
                aria-label={`Column filter for ${header.column.columnDef.header}`}
                aria-pressed={isColumnFiltered}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        togglePopup(header.column.columnDef.accessorKey, header.column.columnDef.header, e); // Trigger the click action on Enter or Space
                    }
                }}
            >
                {isColumnFiltered && <i className="fa-solid fa-circle-check" style={{ color: "#5E9E42", position: "absolute", top: "-6px", left: 7 }} />}
                <img
                    src={filterIcon}
                    alt="Filter icon"
                    style={{
                        filter: isPopupVisible && header.column.columnDef.header === columnName ? 'invert(46%) sepia(27%) saturate(450%) hue-rotate(175deg) brightness(94%) contrast(95%)' : 'none',
                    }}
                />
            </div>
        ), [filterValue, isPopupVisible]);

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (
                    isPopupVisible &&
                    !event.target.closest(".filter-popup-box") &&
                    !event.target.closest("[data-filter-icon]")
                ) {
                    setPopupVisible(false);
                }
            };
            if (isPopupVisible) {
                const firstFocusableElement = document.querySelector('.input-box');
                if (firstFocusableElement) {
                    firstFocusableElement.focus();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [isPopupVisible]);

        // Memoize the column header styles
        const columnHeaderStyles = useMemo(() => ({
            opacity: isDragging ? 0.5 : 1,
            minWidth: header.column.columnDef.minSize,
            maxWidth: header.getSize(),
            paddingLeft: header.index === 0 ? 24 : 8,
            position: 'relative',
        }), [isDragging, header]);

        const handleSortingChange = (header) => {
            let newSorting;
            if (sorting.id === header.column.id) {
                // Toggle sorting state
                if (sorting.desc === false) {
                    newSorting = { id: header.column.id, desc: true, accessorKey: header.column.columnDef.accessorKey }; // Descending
                } else if (sorting.desc === true) {
                    newSorting = { id: null, desc: null, accessorKey: null }; // Reset to initial state
                } else {
                    newSorting = { id: header.column.id, desc: false, accessorKey: header.column.columnDef.accessorKey }; // Ascending
                }
            } else {
                // Set to ascending by default if this column is newly sorted
                newSorting = { id: header.column.id, desc: false, accessorKey: header.column.columnDef.accessorKey };
            }
            setSorting(newSorting);
            getSortDeitals(newSorting);

            const sortOrder = newSorting.desc ? "descending" : "ascending";
            const announcement = `Table sorted by ${header.column.columnDef.header} in ${sortOrder} order.`;
            const liveRegion = document.getElementById("aria-live-region");
            if (liveRegion) {
                liveRegion.textContent = announcement;
                setTimeout(() => {
                    liveRegion.textContent = "";
                }, 1000);
            }
        };

        return (
            <th
                ref={dropRef}
                colSpan={header.colSpan}
                style={columnHeaderStyles}
            >
                <div className="report-header d-flex">
                    <div
                        ref={dragRef}
                        onClick={() => handleSortingChange(header)}
                        style={{
                            backgroundColor: "white",
                            wordBreak: "break-word",
                            textWrap: "nowrap",
                            textAlign: "left",
                        }}
                    >
                        {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                        {
                            filteredDataLength > 0 && (
                                <>
                                    {sorting.id !== header.column.id ? (
                                        <img
                                            src={sortIcon}
                                            alt={`Sort by ${header.column.id}`}
                                            aria-label={`Click to sort by ${header.column.id}`}
                                            tabIndex={0}
                                            role="button"
                                            onClick={() => handleSortingChange(header)}
                                            onKeyDown={(e) => {
                                                if (['Enter', ' '].includes(e.key)) {
                                                    handleSortingChange(header);
                                                }
                                            }}
                                        />
                                    ) : sorting.desc ? (
                                        <i
                                            className="fa-solid fa-arrow-down-wide-short"
                                            style={{ color: "#025780" }}
                                            aria-label={`Sorted by ${header.column.id} in descending order`}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => handleSortingChange(header)}
                                            onKeyDown={(e) => {
                                                if (['Enter', ' '].includes(e.key)) {
                                                    handleSortingChange(header);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <i
                                            className="fa-solid fa-arrow-up-short-wide"
                                            style={{ color: "#025780" }}
                                            aria-label={`Sorted by ${header.column.id} in ascending order`}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => handleSortingChange(header)}
                                            onKeyDown={(e) => {
                                                if (['Enter', ' '].includes(e.key)) {
                                                    handleSortingChange(header);
                                                }
                                            }}
                                        />
                                    )}
                                </>
                            )
                        }
                    </div>
                    {column.id === "SSN" && (
                        <div
                            onClick={toggleSSNVisibility} role="button" tabIndex={!isUninitialized && !isFetching && !isError && data.length > 0 && ssnValues[0] !== "No Access" ? 0 : -1}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    e.preventDefault();
                                    toggleSSNVisibility();
                                }
                            }}
                            aria-label={!isUninitialized && !isFetching && !isError && data.length > 0 && ssnValues[0] !== "No Access" && getSSNIcon()?.props?.['aria-label']}
                            style={{ cursor: "pointer" }}>
                            {!isUninitialized && !isFetching && !isError && data.length > 0 && ssnValues[0] !== "No Access" && getSSNIcon()}
                        </div>
                    )}
                    {filteredDataLength > 0 && FilterIcon}
                </div>
                <div
                    onDoubleClick={header.getResizeHandler()}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
                    role="separator"
                    aria-orientation="horizontal"
                    aria-valuetext="Resize column"
                />
            </th>
        );
    };


    const [data, setData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [isfetchingMoreData, setIsFectchingMoreData] = useState(false);

    useEffect(() => {
        setData(claimData);
    }, [claimData]);

    useEffect(() => {
        setIsFectchingMoreData(isFetchLoading)
    }, [isFetchLoading]);

    const [columnOrder, setColumnOrder] = useState(
        columns.map((column) => column.id)
    );

    useEffect(() => {
        setColumns([...defaultColumns]);
    }, [defaultColumns]);

    useEffect(() => {
        setSorting(
            { id: reportSortingColumnName, desc: reportSortingColumnDirection, accessorKey: reportSortingColumnAccessorKey },
        );
        getSortDeitals(
            { id: reportSortingColumnName, desc: reportSortingColumnDirection, accessorKey: reportSortingColumnAccessorKey },
        );
    }, [reportSortingColumnName, reportSortingColumnDirection]);

    const [showLoader, setShowLoader] = useState(false);
    const [show, setShow] = useState(false);

    const handleItemsPerPageChange = (selectedReport) => {
        getPagePerItems(selectedReport);
        getSortDeitals(sorting);

        setItemsPerPage(selectedReport);
        setPageNumber(0);
    };
    useEffect(() => {
        setPageNumber(0);
    }, [isFetching]);
    const handlePageNumberClick = (pageNumber) => {
        getSortDeitals(sorting);
        handlePageSelect(pageNumber);
        setPageNumber(pageNumber - 1);
    };

    const table = useReactTable({
        data,
        columns: columns.map((column) => ({
            ...column,
        })),


        state: {
            sorting,
            columnOrder,
            columnVisibility,
        },
        columnResizeMode,
        onColumnVisibilityChange: handlecolumnVisibility,
        onColumnOrderChange: setColumnOrder,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    const getTotalWidth = (columns) => {
        return columns
            .filter(column => column.getIsVisible())
            .reduce((total, column) => {
                return total + (column.getSize() || 0);
            }, 0);
    };

    const activeColumnWidth = getTotalWidth(table.getAllColumns());

    // setting defult view in dropdown
    useEffect(() => {
        const keyValuePair = allViewsData.map((obj) => {
            return {
                value: obj.Name,
                label: obj.Name,
                key: obj.ViewId,
            };
        });
        handleSelectedReport(keyValuePair[0]);

        const coverageType = allViewsData.map((obj) => obj.CoverageType)[0];
        handleCoverageType(coverageType);
    }, []);

    useEffect(() => {
        if (isDropdownOpen && firstInteractiveElementRef.current) {
            firstInteractiveElementRef.current.focus();
        }
    }, [isDropdownOpen]);

    // selecting dropdown value
    const handleSelectChange = (selectedReport) => {
        handleOptionChange(selectedReport);
        handleSelectedReport(selectedReport);
        table.resetColumnFilters();
        handleColumnFilter([]);
        setIsDropdownOpen(!isDropdownOpen);
        handleSsnDisplayMode(0);
        setSorting(
            { id: reportSortingColumnName, desc: reportSortingColumnDirection },
        );
        const matchedObject = allViewsData.find(
            (obj) => obj.ViewId === selectedReport.key
        );
        setColumnOrder(matchedObject.ColumnInformation.map((obj) => obj.id));
        handleCoverageType(matchedObject.CoverageType);
        emitFiltersDataForSelectedView(matchedObject);
        setSelectedViewDataType(matchedObject.DataViewType);
        gtm.Event(
            "custom_event",
            "dropdown",
            "select",
            "change report",
            "report detail",
            matchedObject.DataViewType,
            "field_interaction",
            ""
        );
    };

    useEffect(() => {
        if (selectedReport) {
            handleViewID(selectedReport.key);
        }
    }, [handleViewID, selectedReport]);

    // hidecolumns

    useEffect(() => {
        if (selectedReport && selectedReport !== undefined) {
            const matchedObject = allViewsData.find(
                (obj) => obj?.ViewId === selectedReport?.key
            );

            const getColumnVisibility = matchedObject?.ColumnInformation;
            if (getColumnVisibility && getColumnVisibility !== undefined) {
                const hide = {};

                getColumnVisibility.forEach((item) => {
                    hide[item.id] = item.checked;
                });

                getColumnVisibility.forEach((item) => {
                    apikey[item.accessorKey] = item.checked;
                });
                setApiKey(apikey);

                handlecolumnVisibility(hide);
            }
            setSelectedViewDataType(matchedObject?.DataViewType)
        }
    }, [allViewsData, selectedReport]);

    //used for onclick on report on most used reports
    useEffect(() => {
        if (selectedReportView && selectedReportView !== undefined) {
            const keyValuePair = {
                value: selectedReportView.Name,
                label: selectedReportView.Name,
                key: selectedReportView.ViewId,
            };
            setColumnOrder(
                selectedReportView.ColumnInformation.map((obj) => obj.Id)
            );
            handleSelectedReport(keyValuePair);
            handleCoverageType(selectedReportView.CoverageType);
            emitFiltersDataForSelectedView(selectedReportView);
        }
    }, [selectedReportView]);

    const handleResetButton = () => {
        setColumnOrderChange(true);
        setColumnOrder(columns.map((column) => column.id));
        table.resetColumnFilters();
        const resetColumnOrder = {};
        defaultColumns.forEach((item) => {
            resetColumnOrder[item.id] = item.checked;
        });
        handleColumnFilter([])
        handlecolumnVisibility(resetColumnOrder);
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "reset",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const handleSaveButton = () => {
        setShow(true);
        const matchedObject = allViewsData.find(
            (obj) =>
                obj.ViewId === selectedReport.key &&
                obj.OwnerType !== "Standard"
        );
        if (matchedObject && matchedObject.OwnerType === "Personal") {
            setShowEditPage(true);
            setUpdateViewDetails(matchedObject);
        } else {
            setShowEditPage(false);
        }
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "save",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const handleReportViewDontSave = () => {
        setShow(false);
    };

    const handleReportViewSave = async (viewName, viewDescription) => {
        const changedColumnSorting = checkColumnOrder
            .map((id, index) => {
                const matchedObject = sorting.id === id ? sorting : null;
                if (matchedObject) {
                    return {
                        ColumnDisplayOrderId: index + 1,
                        Direction: matchedObject.desc ? "DESC" : "ASC",
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        const changedColumnOrder = checkColumnOrder
            .map((id, index) => {
                const matchedObject = defaultColumns.find(
                    (obj) => obj.id === id
                );

                if (matchedObject) {
                    return {
                        DisplayOrder: index + 1,
                        AccessorKey: matchedObject.accessorKey,
                        Id: matchedObject.id,
                        Header: matchedObject.header,
                        Checked:
                            columnVisibility[id] === undefined
                                ? true
                                : columnVisibility[id],
                        LockedDisplay: matchedObject.enableHiding,
                        Size: matchedObject.size,
                        MinSize: matchedObject.minSize,
                        MaxSize: matchedObject.maxSize,
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        setColumnOrderChange(true);
        setShow(false);
        handleViewReportSave(
            changedColumnSorting,
            changedColumnOrder,
            viewName,
            viewDescription
        );
        const savedViewValue = {
            value: viewName,
            label: viewName,
        };
        handleSelectedReport(savedViewValue);
    };

    useEffect(() => {
        handleSelectedReport((prev) => ({
            ...prev,
            key: selectedViewId,
        }));
    }, [selectedViewId]);

    useEffect(() => {
        let keyValuePair;
        if (selectedReportView && selectedReportView.DataViewType) {
            keyValuePair = allViewsData
                .filter(
                    (obj) =>
                        obj.DataViewType === selectedReportView.DataViewType
                )
                .map((obj) => {
                    return {
                        value: obj.Name,
                        label: obj.Name,
                        key: obj.ViewId,
                        ownerType: obj.OwnerType,
                    };
                });
        } else {
            // If selectedReportView is empty, map allViewsData without filtering
            keyValuePair = allViewsData.map((obj) => {
                return {
                    value: obj.Name,
                    label: obj.Name,
                    key: obj.ViewId,
                    ownerType: obj.OwnerType,
                };
            });
        }
        setReportViewDropDownValues(keyValuePair);
    }, [allViewsData, selectedReportView]);

    useEffect(() => {
        if (loaderStatus === true) {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [loaderStatus]);

    const handleReportViewUpdate = (viewName, viewDescription, viewId) => {
        const changedColumnSorting = checkColumnOrder
            .map((id, index) => {
                const matchedObject = sorting.id === id;

                if (matchedObject) {
                    return {
                        ColumnDisplayOrderId: index + 1,
                        Direction: matchedObject.desc ? "DESC" : "ASC",
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        const changedColumnOrder = checkColumnOrder
            .map((id, index) => {
                const matchedObject = defaultColumns.find(
                    (obj) => obj.id === id
                );
                if (matchedObject) {
                    return {
                        DisplayOrder: index + 1,
                        AccessorKey: matchedObject.accessorKey,
                        Id: matchedObject.id,
                        Header: matchedObject.header,
                        Checked:
                            columnVisibility[id] === undefined
                                ? true
                                : columnVisibility[id],
                        LockedDisplay: matchedObject.enableHiding,
                        Size: matchedObject.size,
                        MinSize: matchedObject.minSize,
                        MaxSize: matchedObject.maxSize,
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        handleViewReportUpdate(
            changedColumnSorting,
            changedColumnOrder,
            updateViewDetails,
            viewName,
            viewDescription
        );
        setShow(false);
        const savedViewValue = {
            value: viewName,
            label: viewName,
            key: viewId,
        };
        handleSelectedReport(savedViewValue);
    };

    const handleManageReportsClick = () => {
        let data = [];
        navigate(`/reportingcapabilities/${reportViewType === "Payments" ? paymentHistoryType : reportViewType}/manageviews`, {
            state: { data, isError },
        });
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "manage my views",
            "report detail",
            selectedViewDataType,
            "navigation_click",
            ""
        );
    };

    useEffect(() => {
        const res = {};
        for (const item of defaultColumns) {
            const { id, checked } = item;
            if (columnVisibility.hasOwnProperty(id)) {
                if (columnVisibility[id] !== checked) {
                    res[id] = checked;
                }
            }
        }
        if (Object.keys(res).length > 0) {
            setColumnOrderChange(false);
        } else {
            setColumnOrderChange(true);
        }
    }, [columnVisibility]);

    useEffect(() => {
        if (sorting[0]) {
            gtm.Event(
                "custom_event",
                "button",
                "click",
                "sort",
                "report detail",
                selectedViewDataType,
                "field_interaction",
                sorting[0].id
            );
        }

        const isMatching = sorting.id === reportSortingColumnName &&
            sorting.desc === reportSortingColumnDirection;
        setSortingColumnDetails(isMatching);
        getSortDeitals(sorting);
    }, [sorting]);

    const dropdownRef = useRef(null);
    const toggleRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const isClickedOutside = useOutsideClick(dropdownRef, toggleRef);

    useEffect(() => {
        if (isClickedOutside) {
            setIsDropdownOpen(false);
        }
    }, [isClickedOutside]);

    const groupedOptions = {};
    reportViewDropDownValues.forEach((option) => {
        if (!groupedOptions[option.ownerType]) {
            groupedOptions[option.ownerType] = [];
        }
        groupedOptions[option.ownerType].push(option);
    });

    const ssnValues = table
        .getRowModel()
        .rows.map((row) => row.renderValue("SSN"));


    const getSSNDisplay = (cell, index) => {
        // Define a mapping object for organizationsAccess.SSNACCESS levels
        const ssnAccessLevels = {
            "No Access": () => {
                if (cell.column.id === "SSN") {
                    return "No Access";
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
            Partial: () => {
                if (cell.column.id === "SSN") {
                    if (ssnDisplayMode === 0) {
                        return "***-**-****";
                    } else if (ssnDisplayMode === 1) {
                        return ssnValues[index]?.substr(-4);
                    } else {
                        return "";
                    }
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
            Full: () => {
                if (cell.column.id === "SSN") {
                    if (ssnDisplayMode === 0) {
                        return "***-**-****";
                    } else if (ssnDisplayMode === 1) {
                        return ssnValues[index]?.substr(-4);
                    } else if (ssnDisplayMode === 2) {
                        return ssnValues[index];
                    } else {
                        return "";
                    }
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
        };

        const displaySSN = ssnAccessLevels[organizationsAccess?.SSNACCESS];

        return displaySSN
            ? displaySSN()
            : flexRender(cell.column.columnDef.cell, cell.getContext());
    };

    const topScrollbarRef = useRef(null);
    const tableContentRef = useRef(null);
    const topScrollBarContentRef = useRef(null);
    const iconAdjustmentWidth = 40;
    let getCenterTotalSize = table.getCenterTotalSize();

    useEffect(() => {
        const topScrollbar = topScrollbarRef.current;
        const tableContent = tableContentRef.current;
        if (!topScrollbar || !tableContent) {
            return;
        }
        const syncScrollFromTable = () => {
            if (topScrollbar && tableContent) {
                topScrollbar.scrollLeft = tableContent.scrollLeft;
            }
        };

        const syncScrollFromTopScrollbar = () => {
            if (topScrollbar && tableContent) {
                tableContent.scrollLeft = topScrollbar.scrollLeft;
            }
        };

        const updateScrollbarWidth = () => {
            if ((topScrollbar && tableContent) || getCenterTotalSize) {
                const totalTableWidth = table.getCenterTotalSize() + iconAdjustmentWidth;
                topScrollbar.firstChild.style.width = `${totalTableWidth}px`;
            }
        };

        // Initial width update
        updateScrollbarWidth();

        // Adding event listeners for scroll synchronization
        tableContent.addEventListener("scroll", syncScrollFromTable);
        topScrollbar.addEventListener("scroll", syncScrollFromTopScrollbar);

        // Cleanup event listeners on component unmount
        return () => {
            tableContent.removeEventListener("scroll", syncScrollFromTable);
            topScrollbar.removeEventListener(
                "scroll",
                syncScrollFromTopScrollbar
            );
        };
    }, [topScrollbarRef, tableContentRef, isFetching, showLoader, isDataFormattingComplete]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const existingFilter = columnFilter.find(
        (filter) => filter.accessorKey === accessorKey
    );
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            closePopup();
        } else if (event.key === "Tab") {
            const focusableElements = Array.from(document.querySelectorAll(
                ".filter-popup-box button, .filter-popup-box input, .filter-popup-box i, .close-area"
            )).filter((el) => !el.disabled);
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];
            if (event.shiftKey && document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
            } else if (!event.shiftKey && document.activeElement === lastElement) {
                event.preventDefault();
                firstElement.focus();
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (isPopupVisible) {
                closePopup();
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isPopupVisible, closePopup]);

    const handleDropdownKeyDown = (event) => {
        if (!isDropdownOpen && (event.key === 'ArrowDown' || event.key === 'Enter' || event.key === ' ')) {
            event.preventDefault();
            toggleDropdown();
        }
        if (event.key === "Escape") {
            setIsDropdownOpen(false) // Close the dialog on Escape key
        } else if (event.key === "Tab") {
            const focusableElements = Array.from(dropdownRef.current.querySelectorAll(
                "li"
            ))
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];
            if (event.shiftKey && document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
            } else if (!event.shiftKey && document.activeElement === lastElement) {
                event.preventDefault();
                firstElement.focus();
            }
        }
    };

    return (
        <>
            {isPopupVisible && ReactDOM.createPortal && (
                <div
                    className="filter-popup-box section-card"
                    role="dialog"
                    aria-labelledby="filter-popup-title"
                    aria-describedby="filter-popup-description"
                    style={{ top: isPopupPosition.top, left: isPopupPosition?.left }}
                    aria-modal="true"
                    onKeyDown={(e) => handleKeyDown(e)}
                >
                    <div className="top-line">
                        <div className="title" id="filter-popup-title">
                            <img
                                src={filterIcon}
                                alt="Filter Icon"
                            />
                            <label>
                                Filter {columnName}
                            </label>
                        </div>

                        <div
                            className="close-area"
                            tabIndex={0}
                            role="button"
                            aria-label="Close filter popup"
                            onClick={closePopup}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") closePopup();
                            }}
                        >
                            <span className="close-text">Close</span>
                        </div>
                    </div>
                    <div className="filter-box" id="filter-popup-description">
                        <i className="fa fa-search search" aria-hidden="true"></i>
                        <input
                            type="text"
                            value={filterValue}
                            onChange={(e) => handleFilterChange(e)}
                            placeholder={`Filter`}
                            className="input-box"
                            aria-label={`Filter input for ${columnName}`}
                            autoFocus
                        />
                    </div>
                    <div className="fs d-flex" style={{ justifySelf: "end", width: "50%", marginTop: 16 }}>
                        <Button
                            text="Apply"
                            onClick={confirmFilter}
                            disabled={!filterValue.trim() || existingFilter?.filterValue === filterValue}
                            aria-disabled={!filterValue.trim()}
                        />
                    </div>
                </div>
            )}

            <div
                className="section-card row d-flex "
                style={{ padding: 0, margin: 0 }}
            >
                {showLoader ? (
                    <Loader text="Saving" />
                ) : (
                    <div className="p-0">
                        <div
                            style={{
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 24,
                            }}
                        >
                            <div
                                className="d-flex justify-content-between report-table"
                                style={
                                    (isFetching || isfetchingMoreData) && !isError
                                        ? {
                                            pointerEvents: "none",
                                            opacity: "0.4",
                                        }
                                        : {}
                                }
                            >
                                <div className="d-flex gap-3" style={{ flexWrap: "wrap" }}>
                                    <div
                                        className="report-view-dropdown"
                                        ref={dropdownRef}
                                        aria-haspopup="listbox"
                                        aria-expanded={isDropdownOpen}
                                        aria-controls="dropdown-options"
                                        role="combobox"
                                        tabIndex={0}
                                        aria-label={`${selectedReport?.label}`}
                                        onKeyDown={(e) => handleDropdownKeyDown(e)}
                                    >
                                        <div
                                            className="d-flex justify-content-between align-items-center cursor-pointer abscenceDropDown"
                                            ref={toggleRef}
                                            onClick={toggleDropdown}
                                            aria-label="Toggle dropdown"
                                        >
                                            <div className="dropdown-header">
                                                {selectedReport?.label || "Select"}
                                            </div>
                                            <i
                                                className="fa-solid fa-chevron-down"
                                                style={{ fontSize: 16 }}
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                        {isDropdownOpen && (
                                            <div
                                                className="section-card custom-scrollbar dropdowm-container"
                                                style={{ borderRadius: 4 }}
                                                tabIndex={-1}
                                            >
                                                {Object.entries(groupedOptions).map(([ownerType, options], index) => (
                                                    <div key={index} className="dropdown-section">
                                                        <h6 className="fw-bold">{ownerType}</h6>
                                                        <ul className="dropdown-options">
                                                            {options.map((option, idx) => (
                                                                <li
                                                                    className={
                                                                        selectedReport &&
                                                                            selectedReport.value ===
                                                                            option.value
                                                                            ? "selected-option"
                                                                            : ""
                                                                    }
                                                                    key={idx}
                                                                    role="option"
                                                                    aria-selected={selectedReport?.value === option.value}
                                                                    onClick={() => handleSelectChange(option)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') handleSelectChange(option);
                                                                    }}
                                                                    ref={index === 0 && idx === 0 ? firstInteractiveElementRef : null}
                                                                    tabIndex={0}
                                                                >
                                                                    {option.label}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        {index !== Object.keys(groupedOptions).length - 1 && (
                                                            <Divider className="mb-0p" mx="mt-0p " />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <ModifyViewButton
                                        table={table}
                                        selectedViewDataType={
                                            selectedViewDataType
                                        }
                                    />
                                </div>

                                <div className="d-flex align-items-center gap-3">
                                    <div className="data-view-button w-auto">
                                        <ReportButtons
                                            label="Save"
                                            iconClass="fa-solid fa-floppy-disk pl-8p"
                                            onClick={handleSaveButton}
                                            isDisabled={
                                                sortingColumnDetails &&
                                                (selectedFilterStatus === false
                                                    ? selectedFilterStatus
                                                    : columnOrderChange)
                                            }
                                            role="button"
                                            ariaLabel="Save the current report view"
                                        />
                                    </div>
                                    <div
                                        className="vertical-divider"
                                        style={{ margin: 0 }}
                                    ></div>
                                    <div className="data-view-button w-auto">
                                        <ReportButtons
                                            label="Reset"
                                            iconClass="fa-solid fa-arrow-rotate-left"
                                            onClick={handleResetButton}
                                            isDisabled={
                                                selectedFilterStatus === false
                                                    ? selectedFilterStatus
                                                    : columnOrderChange
                                            }
                                            role="button"
                                            ariaLabel={"Reset the current report view"}
                                        />
                                    </div>
                                    <div
                                        className="vertical-divider"
                                        style={{ margin: 0 }}
                                    ></div>
                                    <div className="data-view-button w-auto" >
                                        <ReportButtons
                                            label="Manage my reports"
                                            onClick={handleManageReportsClick}
                                            iconClass="fa-solid fa-user-cog"
                                            ariaLabel="Manage my reports"
                                            isDisabled={false}
                                            role="link"

                                        />

                                    </div>
                                    {
                                        originalDataLength > 0 &&
                                        <>
                                            <div
                                                className="vertical-divider"
                                                style={{ margin: 0 }}
                                            ></div>
                                            <div className="data-view-button w-auto" >
                                                <ReportButtons
                                                    label="Export data"
                                                    onClick={() => handleExportData(columnOrder)}
                                                    ariaLabel="Export data"
                                                    isDisabled={false}
                                                    role="button"
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <p className="report-header pt-2">
                                {description}
                                {!isfetchingMoreData && !isError && !isFetching && (
                                    <>
                                        <strong style={{ color: "#015294" }}>
                                            | Total records: {originalDataLength}
                                        </strong>
                                    </>
                                )}
                            </p>

                            {isfetchingMoreData && !isError && (
                                <div className="total-table">
                                    <div className="spinner"></div>
                                    &nbsp;Retrieving {Math.min(pagecounter * pagingCount, totalResults)}{" "}
                                    of {totalResults} records.
                                </div>
                            )}
                        </div>
                        {(isFetching || isDataFormattingComplete) ? (
                            <Loader />
                        ) : (
                            <div
                                className="custom-table-container"
                                style={{
                                    paddingBottom: filteredDataLength > 20 ? 0 : 16,
                                }}
                            >
                                <div
                                    className="custom-topscrollbar"
                                    ref={topScrollbarRef}
                                    tabIndex={0}
                                    role="scrollbar"
                                >
                                    {/* Render some content to visualize the scrollbar */}
                                    <div
                                        className="scrollbar-content"
                                        style={{
                                            width: getCenterTotalSize,
                                            height: "15px",
                                        }}
                                        ref={topScrollBarContentRef}
                                    ></div>
                                </div>
                                <div>
                                    {columnFilter.length > 0 ? (
                                        <>
                                            {activeColumnWidth < 1180 && (
                                                <div className="horizontal-bar"></div>
                                            )}
                                            <div>
                                                <div className="filters-applied-area">
                                                    Filters applied:{' '}
                                                    {columnFilter.map((filter, index) => (
                                                        <div className="filters-applied-box" key={index}>
                                                            <span className="pr-10p">
                                                                {filter.columnName}: <strong>{filter.filterValue}</strong>
                                                            </span>
                                                            <button
                                                                className="filters-applied-clear-value"
                                                                onClick={() => {
                                                                    // Clear the specific filter
                                                                    const updatedFilters = columnFilter.filter((_, i) => i !== index);

                                                                    if (updatedFilters.length > 0) {
                                                                        setClearColumnFilter(updatedFilters);
                                                                    } else {
                                                                        handleColumnFilter([])
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-x"></i>
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button
                                                        className="filters-applied-clear-all"
                                                        onClick={() => handleColumnFilter([])}
                                                    >
                                                        Clear All
                                                    </button>
                                                </div>
                                                <div className="horizontal-bar"></div>
                                            </div>
                                        </>


                                    ) : (
                                        <></> // If no filters are applied, nothing is rendered
                                    )}


                                </div>
                                <div
                                    className="custom-table-content "
                                    ref={tableContentRef}
                                >
                                    <table
                                        {...{
                                            style: {
                                                width: getCenterTotalSize,
                                            },
                                        }}
                                    >
                                        <thead id="reportingData">
                                            {table
                                                .getHeaderGroups()
                                                .map((headerGroup) => (
                                                    <tr key={headerGroup.id}>
                                                        {headerGroup.headers.map(
                                                            (header) => (
                                                                <DraggableColumnHeader
                                                                    key={
                                                                        header.id
                                                                    }
                                                                    header={
                                                                        header
                                                                    }
                                                                    table={
                                                                        table
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </tr>
                                                ))}
                                        </thead>
                                        {!isError && (
                                            <tbody>
                                                {filteredDataLength === 0 && columnFilter.length > 0 ? (
                                                    <tr>
                                                        <td colSpan={table.getAllColumns().length} style={{ textAlign: 'center' }}>
                                                            No data matches the current filter.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    table
                                                        .getRowModel()
                                                        .rows.map((row, index) => (
                                                            <tr
                                                                className={
                                                                    (index % 2 === 0
                                                                        ? "even "
                                                                        : "odd ") +
                                                                    (index === 0
                                                                        ? "first "
                                                                        : "")
                                                                }
                                                                key={row.id}
                                                            >
                                                                {row
                                                                    .getVisibleCells()
                                                                    .map((cell) => (
                                                                        <td
                                                                            key={
                                                                                cell.id
                                                                            }
                                                                            style={{
                                                                                width: cell.column.getSize(),
                                                                                textAlign:
                                                                                    "left",
                                                                                wordBreak:
                                                                                    "break-all",
                                                                            }}
                                                                            className="td"
                                                                        >
                                                                            {ssnValues[0] ===
                                                                                "No Access"
                                                                                ? flexRender(
                                                                                    cell
                                                                                        .column
                                                                                        .columnDef
                                                                                        .cell,
                                                                                    cell.getContext()
                                                                                )
                                                                                : getSSNDisplay(
                                                                                    cell,
                                                                                    index
                                                                                )}
                                                                        </td>
                                                                    ))}
                                                            </tr>
                                                        ))
                                                )}
                                            </tbody>
                                        )}
                                    </table>
                                    {!isUninitialized &&
                                        !isFetching &&
                                        !isError &&
                                        originalDataLength === 0 && (
                                            <div
                                                style={{
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    paddingTop: 24,
                                                }}
                                            >
                                                <NoRecords
                                                    page="search"
                                                    label="search"
                                                    attribute1="search"
                                                    attribute3="search_error"
                                                />
                                            </div>
                                        )}
                                    {!isUninitialized && isError && (
                                        <div
                                            style={{
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                marginTop: -6,
                                                paddingBottom: 24,
                                            }}
                                        >
                                            <RequestFailed
                                                tabIndex={0}
                                                message={
                                                    "Your request failed, please try again or contact us for further assistance."
                                                }
                                                label="search"
                                                attribute1="employee search"
                                                attribute3="employee_error"
                                            />
                                        </div>
                                    )}
                                </div>
                                {Boolean(getCenterTotalSize) && filteredDataLength > 20 && (
                                    <div className="scrollbar-corner"></div>
                                )}
                            </div>
                        )}

                        {filteredDataLength > 20 && !isFetching && !isUninitialized && !isError && (
                            <div className="py-20p">
                                <Pagination
                                    pageCount={getPageCount}
                                    gotoPage={(index) =>
                                        handlePageNumberClick(index + 1)
                                    }
                                    setPageSize={handleItemsPerPageChange}
                                    pageSize={itemsPerPage}
                                    options={[20, 50, 100]}
                                />
                            </div>
                        )}
                        {
                            <ReportSavingPage
                                onShow={show}
                                onDontSave={handleReportViewDontSave}
                                onSave={handleReportViewSave}
                                reportViewType={selectedViewDataType}
                            />
                        }
                        {showEditPage ? (
                            <ReportEditPage
                                onShow={show}
                                onDontSave={handleReportViewDontSave}
                                onSave={handleReportViewUpdate}
                                onSaveAs={handleReportViewSave}
                                notification={notificationStatus}
                                reportDetails={updateViewDetails}
                            />
                        ) : (
                            <ReportSavingPage
                                onShow={show}
                                onDontSave={handleReportViewDontSave}
                                onSave={handleReportViewSave}
                                notification={notificationStatus}
                                reportViewType={selectedViewDataType}
                            />
                        )}
                    </div>
                )}
            </div > {" "}
            <div id="aria-live-region" style={{ position: "absolute", left: "-9999px" }} aria-live="polite"></div>
        </>
    );
}
export default ReportTable;
