import React from "react";
import { useDispatch } from "react-redux";
import { addClaimNo } from "./../../slices/leaveClaimRecordsSlice";
import SectionHeading from "../../components/SectionHeading";
import config from "./../../config";
import { noDataPlaceholder } from "../../constants";
import { checkNullValue, formattedDate } from "../../utils/helpers";
import GTM from "../../GTM";


function LeaveSummary({ leaveDetailsEmp, organizationAccess,user }) {
    
    const checkNull = (inputobject) => {
        let retval = null;
        if (inputobject != null) {
            if (inputobject.length > 0) {
                retval = inputobject[0];
            }
        }
        return retval;
    };
    const getOutstandingLink = () => {
        return user?.isUserInternal === true
            ? config.REACT_APP_OUTSTANDING_INFO_ISERVICES_URL
            : config.REACT_APP_ISERVICES_SSO_URL +
                  config.REACT_APP_OUTSTANDING_INFO_ISERVICES_URL;
    };
    const getHistoryLink = () => {
        return user?.isUserInternal === true
            ? config.REACT_APP_ISERVICES_URL
            : config.REACT_APP_ISERVICES_SSO_URL +
            config.REACT_APP_ISERVICES_URL;
    };
    const Header = checkNull(leaveDetailsEmp.Header);
    const Summary = checkNull(leaveDetailsEmp.LeaveSummary);

    const dispatch = useDispatch();

    const gtm = new GTM(null);
    const handelClick = (label, attribute3) => {
        gtm.Event(
            "claim & leave",
            "link",
            "click",
            label,
            "leave summary",
            "",
            attribute3,
            ""
        );
    };

    return (
        <div className="section-card pb-0">
            <div className="d-flex align-items-center mb-20p">
                <SectionHeading
                    heading="Leave Summary"
                    mb="md-fs"
                    className="mr-20p"
                />
                {Header?.LeaveStatus === "Open" && (
                    <a
                        className="link text-semibold"
                        target="_blank"
                        href={
                            getOutstandingLink() +
                            Header?.LeaveNo
                        }
                        rel="noreferrer"
                        onClick={() => {
                            handelClick(
                                "outstanding information",
                                "navigation_click"
                            );
                        }}
                    >
                        Outstanding Information
                    </a>
                )}
            </div>

            <div className="row">
                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Leave #</label>
                    <span className="d-block">{Header?.LeaveNo}</span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Leave Reason</label>
                    <span className="d-block">
                        {checkNullValue(
                            Header.LeaveReason,
                            <span role="data" aria-label="NoData">
                                {noDataPlaceholder}
                            </span>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Leave Status</label>
                    <span className="d-block">
                        {checkNullValue(
                            Header.LeaveStatus,
                            <span role="data" aria-label="NoData">
                                {noDataPlaceholder}
                            </span>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Report Group</label>
                    <span className="d-block">
                        {checkNullValue(
                            Header.ReportGroup,
                            <span role="data" aria-label="NoData">
                                {noDataPlaceholder}
                            </span>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Historical</label>
                    <span className="d-block">
                        {Header?.Historical === "Y" ? "Yes" : "No"}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Leave History</label>

                    {organizationAccess?.HISTORICALLEAVEACCESS === "Show" ? (
                        <a
                            target="_blank"
                            href={
                                getHistoryLink() + Header?.LeaveNo
                            }
                            className="d-block link"
                            rel="noreferrer"
                            title="View Leave History link"
                            onClick={() => {
                                handelClick("view", "view_Leave_history");
                            }}
                        >
                            View
                        </a>
                    ) : (
                        <span className="d-block">No Access</span>
                    )}
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Received Date</label>
                    <span className="d-block">
                        {formattedDate(Summary.ReceivedDate, {
                            noDataPlaceholder,
                        })}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Last Day Worked</label>
                    <span className="d-block">
                        {formattedDate(
                            Summary.LastDayWorked,
                            <span role="data" aria-label="NoData">
                                {noDataPlaceholder}
                            </span>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Leave Start Date</label>
                    <span className="d-block">
                        {formattedDate(
                            Summary.LeaveStartDate,
                            <span role="data" aria-label="NoData">
                                {noDataPlaceholder}
                            </span>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Office Location</label>
                    <span className="d-block">
                        {checkNullValue(
                            Summary.OfficeLocation,
                            <span role="data" aria-label="NoData">
                                {noDataPlaceholder}
                            </span>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Leave End Date</label>
                    <span className="d-block">
                        {formattedDate(
                            Summary.LeaveEndDate,
                            <div role="data" aria-label="NoData">
                                {noDataPlaceholder}
                            </div>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Return to Work Date</label>
                    <span className="d-block">
                        {Summary.RTWStatus.trim() === ""
                            ? formattedDate(Summary.RTWDate)
                            : `${formattedDate(Summary.RTWDate)} - ${
                                  Summary.RTWStatus
                              }`}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Leave Specialist</label>
                    <span className="d-block">
                        {checkNullValue(
                            Summary.LeaveSpecialist,
                            <span role="data" aria-label="No Data">
                                {noDataPlaceholder}
                            </span>
                        )}
                    </span>
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">
                        Related STD/LTD Claims
                    </label>

                    {Summary?.RelatedSTDClaims ? (
                        <div className="d-block">
                            {Summary.RelatedSTDClaims.split(",").map((n) => (
                                <a
                                    className="cursor-pointer d-inline-block link mr-10p"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(addClaimNo(n));
                                        handelClick(
                                            "related std/ltd claims",
                                            "field_interaction"
                                        );
                                    }}
                                    key={n}
                                    title="View Related STD/LTD Claims"
                                    tabIndex={0}
                                >
                                    {n}
                                </a>
                            ))}
                        </div>
                    ) : (
                        <span
                            className="d-block"
                            role="data"
                            aria-label="NoData"
                        >
                            {noDataPlaceholder}
                        </span>
                    )}
                </div>

                <div className="col-lg-3 col-sm-4 col-6 col-print mrgbot-20p">
                    <label className="section-label">Manager Name</label>
                    <span className="d-block">
                        {checkNullValue(
                            Summary.ManagerName,
                            <span title="No Data">{noDataPlaceholder}</span>
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default LeaveSummary;
